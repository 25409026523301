import React from 'react';
import { DbCredito } from "../Db";
import { setCredito, SetNumbers, SetNumbersAll } from "../reducers";
import Api from "../Api";

export enum RUOLO {
    GUEST = 0,
    TEMPORANEO = 3,
    CITTADINO = 5,
    CLIENTE = 10,
    FORNITORE = 30,
    COGESTORE = 50,
    ADMIN = 100,
}

export async function reloadNumbers(dispatch: any) {
    var data = await Api.Numbers.all(dispatch);
    if (data) {
        var numbers: Array<any> = [];
        data.map((o, i) => {
            numbers.push({ id: o.id, value: o.tot });
            /*            dispatch(SetNumbers({id: o.id, value: o.tot}));*/
        });
        dispatch(SetNumbersAll(numbers));
    }
}

export function formatNumOrdine(num) {
    if (num == null)
        num = 0;
    const size = 5;
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export async function reloadCredito(root: any, dispatch: any) {
    if (root?.isLogged == true)
        dispatch(setCredito((await DbCredito(dispatch))));
}

export function Log(obj) {
    alert(JSON.stringify(obj));
}

export function downloadMedia(guid) {
    window.open(`/db/media/get?guid=${guid}`);
}

export function currencyFormat(num) {
    try {
        return (
            num
                .toFixed(2) // always two decimal digits
                .replace('.', ',') // replace decimal point character with ,
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
        ) // use . as a separator
    } catch (e) {
        return '0 €';
    }
}

export function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
}

export function IsNullOrEmpty(obj) {
    if (obj == null)
        return null;
    return Object.entries(obj).length <= 0;
}

export function IsNullOrEmptyBool(obj): boolean | undefined {
    if (obj == null)
        return true;
    return Object.entries(obj).length <= 0;

}
export function HasGrant(root, ruolo: RUOLO) {
    const x = ruolo as number;
    const y = RUOLO[root.LoginData.role] as unknown as number;
    return x <= y;
}

export function IsRuolo(ruoloOrig: string, ruoloDest: RUOLO) {
    if (ruoloOrig == null)
        return false;
    return RUOLO[ruoloOrig.toUpperCase()] == (ruoloDest as unknown as string);
}

export function showLogin(root: any, location: any) {
    return root?.LoginData?.name == null && root?.isLogged == false && !location.pathname.includes("/registrati");
}

export function validationState(item: any, validation: any, setValidation: any) {
    let ok = true;
    if (ok == true) {
        let items = [...validation];
        Object.keys(validation).forEach(function (key, index) {
            validation.map((val, index) => {
                if (ok == true) {
                    const value = item != null ? item[val.id] : null;
                    if (ok == true && val.required == true && (value == null || value == "")) {
                        let item = { ...items[index] };
                        item.error = true;
                        item.helperText = "questo campo è obbligatorio";
                        items[index] = item;
                        const el = window.document.getElementsByName(item.id as string)[0] as HTMLElement;
                        if (el != null)
                            el.focus();
                        ok = false;
                    }
                    /*REGEX*/
                    if (ok == true && val.isEmail == true && value != null && value != "") {
                        let item = { ...items[index] };
                        items[index] = item;
                        const el = window.document.getElementsByName(item.id as string)[0] as HTMLElement;
                        const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
                        const result = regex.test(value);
                        if (result == false) {
                            item.error = true;
                            item.helperText = "questo campo deve essere una mail valida";
                            el.focus();
                            ok = false;
                        }
                    }
                    if (ok == true) {
                        let item = { ...items[index] };
                        item.error = false;
                        item.helperText = null;
                        items[index] = item;
                        setValidation(items);
                    }
                    setValidation(items);
                }
            });
        });
    }
    return ok;
}

export function validationError(validation: Array<any>, id: string) {
    return validation.filter(x => x.id == id)[0]?.error ?? false;
}

export function validationMessage(validation: Array<any>, id: string) {
    return validation.filter(x => x.id == id)[0]?.helperText ?? null;
}

export function kFormatter(num) {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + 'K';
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num < 900) {
        return num;
    }
    else {
        return num;
    }
}

export function getProdottoImmagine(prod, w?: number) {
    if (prod.image_url == null)
        return `/db/media/get?guid=${prod?.guid_immagine}&w=${w ?? ""}`
    return prod.image_url
}

export function getNewProdottoImmagine(prod, w?: number) {
    if (prod.image_url == null)
        return `/db/media/get?guid=${prod?.guid_media}&w=${w ?? ""}`
    return prod.image_url
}

export function isSmallScreen() {
    return window.innerWidth <= 1420;
}

export function getLabel(key, root) {
    if (root.Labels?.data && root.Labels?.data.filter(x => x.key.toLowerCase() == key.toLowerCase()).length > 0) {
        return root.Labels.data.filter(x => x.key.toLowerCase() == key.toLowerCase())[0].value;
    }
    return key;
}