import React from "react";
import {createBrowserRouter, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/home/Home";
import {createRoutesFromElements} from "react-router";
import Anagrafiche from "./pages/anagrafiche/Anagrafiche";
import AnagraficaEdit from "./pages/anagrafiche/AnagraficaEdit";
import CambioPasswordReport from "./pages/dorsale-menu/cambio-pass-report/CambioPasswordReport";
import StepperRegister from "./pages/register/StepperRegister";
import Prodotti from "./pages/articoli/prodotti-servizi/Prodotti";
import ProdottoEdit from "./pages/articoli/prodotti-servizi/ProdottoEdit";
import Categorie from "./pages/articoli/categoria/Categorie";
import CategoriaEdit from "./pages/articoli/categoria/CategoriaEdit";
import CartCategorie from "./pages/catalogo/CartCategorie";
import CartSottoCategorie from "./pages/catalogo/CartSottoCategorie";
import CartProdotti from "./pages/catalogo/CartProdotti";
import SottoCategorie from "./pages/articoli/categoria/sottocategoria/SottoCategorie";
import SottoCategoriaEdit from "./pages/articoli/categoria/sottocategoria/SottoCategoriaEdit";
import Privacy from "./pages/dorsale-menu/privacy/Privacy";
import PrivacyEdit from "./pages/dorsale-menu/privacy/PrivacyEdit";
import Ordini from "./pages/ordini/Ordini";
import OrdineEdit from "./pages/ordini/OrdineEdit";
import DorsaleEdit from "./pages/dorsale-menu/config-dorsale/DorsaleEdit";
import Condizioni from "./pages/dorsale-menu/condizioni-vendita/Condizioni";
import CondizioniEdit from "./pages/dorsale-menu/condizioni-vendita/CondizioniEdit";
import News from "./pages/dorsale-menu/news/News";
import NewsEdit from "./pages/dorsale-menu/news/NewsEdit";
import NewsCategorie from "./pages/dorsale-menu/news/categoria/NewsCategorie";
import NewsCategoriaEdit from "./pages/dorsale-menu/news/categoria/NewsCategoriaEdit";
import NewsMacroCategorie from "./pages/dorsale-menu/news/macrocategoria/NewsMacroCategorie";
import NewsMacroCategoriaEdit from "./pages/dorsale-menu/news/macrocategoria/NewsMacroCategoriaEdit";
import Bilancio from "./unused/bilancio/Bilancio";
import BilancioEdit from "./unused/bilancio/BilancioEdit";
import SchedulatoreEdit from "./pages/dorsale-menu/schedulatore/SchedulatoreEdit";
import SchedulatoreHistory from "./pages/dorsale-menu/schedulatore/history/SchedulatoreHistory";
import Settori from "./pages/articoli/settori/Settori";
import SettoreEdit from "./pages/articoli/settori/SettoreEdit";
import Logs from "./components/Logs";
import ImportEdit from "./pages/dorsale-menu/azioni-massive/ImportEdit";
import ManifestEdit from "./pages/dorsale-menu/manifest/ManifestEdit";
import ThemeEdit from "./pages/dorsale-menu/theme/ThemeEdit";
import StripePayment from "./pages/pagamento/StripePayment";
import StripeOkCarta from "./components/stripe/StripeOkCarta";
import StripeOkOffline from "./components/stripe/StripeOkOffline";
import RicaricaStripe from "./pages/pagamento/ricarica/RicaricaStripe";
import RichiestePrezzo from "./pages/articoli/richieste-prezzi/preventivi/RichiestePrezzo";
import RichiestaPrezzoDettagli from "./pages/articoli/richieste-prezzi/prodotti/RichiestaPrezzoDettagli";
import NewsLettura from "./pages/dorsale-menu/news/news-tracking/NewsLettura";
import Banners from "./pages/dorsale-menu/banners/Banners";
import BannerEdit from "./pages/dorsale-menu/banners/BannerEdit";
import BannerStats from "./pages/dorsale-menu/banners/BannerStats";
import OpenInBrowser from "./components/OpenInBrowser";
import MailTemplates from "./pages/dorsale-menu/templates/mail/MailTemplates";
import ChangePassword from "./pages/login/ChangePassword";
import Videos from "./pages/dorsale-menu/videos/Videos";
import VideoEdit from "./pages/dorsale-menu/videos/VideoEdit";
import TemplateOrdini from "./pages/dorsale-menu/templates/ordini/TemplateOrdini";
import TemplateOrdiniEdit from "./pages/dorsale-menu/templates/ordini/TemplateOrdiniEdit";


export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Layout />}>
            <Route path='/' element={<Home />} />
            <Route path="report" element={<Home />} />
            <Route path='dashboard' element={<Home />} />
            <Route path='anagrafiche/:ruolo' element={<Anagrafiche />} />
            <Route path='registrazione' element={<AnagraficaEdit />} />
            <Route path='i-miei-dati' element={<AnagraficaEdit />} />
            <Route path='anagrafiche/:ruolo/:guid/:seo' element={<AnagraficaEdit />} />
            <Route path='anagrafiche/:ruolo/inserisci' element={<AnagraficaEdit />} />
            <Route path='cambiopasswordreport' element={<CambioPasswordReport />} />
            <Route path='registrati/:ruolo/:email?' element={<StepperRegister />} />
            <Route path='servizi' element={<Prodotti />} />
            <Route path='prodotti' element={<Prodotti />} />
            <Route path='logisitca' element={<Prodotti />} />
            <Route path='prodotti/edit/:sku' element={<ProdottoEdit />} />
            <Route path='servizi/edit/:sku' element={<ProdottoEdit />} />
            <Route path='logisitca/edit/:sku' element={<ProdottoEdit />} />
            <Route path='prodotti/nuovo/inserisci' element={<ProdottoEdit />} />
            <Route path='servizi/nuovo/inserisci' element={<ProdottoEdit />} />
            <Route path='logistica/nuovo/inserisci' element={<ProdottoEdit />} />
            <Route path='categoria' element={<Categorie />} />
            <Route path='categoria/nuovo/inserisci' element={<CategoriaEdit />} />
            <Route path='categoria/edit/:guid?/:seo?' element={<CategoriaEdit />} />

            <Route path='catalogo/:tipologia' element={<CartCategorie />} />
            <Route path='catalogo/:tipologia/:guidcategoria/:seo' element={<CartSottoCategorie />} />
            <Route
                path='catalogo/:tipologia/:guidcategoria/:nomecategoria/:guidsottocategoria/:seo'
                element={<CartProdotti />}
            />
            <Route
                path='catalogo/:tipologia/:guidcategoria/:nomecategoria/:guidsottocategoria/:seo/:sku'
                element={<CartProdotti />}
            />
            <Route path='sottocategoria' element={<SottoCategorie />} />
            <Route path='sottocategoria/edit/:guid?/:seo?' element={<SottoCategoriaEdit />} />
            <Route path='sottocategoria/nuovo/inserisci' element={<SottoCategoriaEdit />} />
            <Route path='privacy' element={<Privacy />} />
            <Route path='privacy/edit/:guid?/:seo?' element={<PrivacyEdit />} />
            <Route path='privacy/nuovo/inserisci' element={<PrivacyEdit />} />
            <Route path='in-evidenza' element={<CartProdotti />} />
            <Route path='ordini' element={<Ordini />} />
            <Route path='ordini/edit/:guid/:seo' element={<OrdineEdit />} />
            <Route path="configurazione-dorsale" element={<DorsaleEdit />} />

            <Route path="condizioni-di-vendita">
                <Route index={true} element={<Condizioni />} />
                <Route path="edit/:guid/:seo?" element={<CondizioniEdit />} />
                <Route path="nuovo/inserisci" element={<CondizioniEdit />} />
            </Route>

            <Route path="news">
                <Route path=":categoria?" element={<News />} />
                <Route path="edit/:guid?/:seo?/:conferma?" element={<NewsEdit />} />
                <Route path="nuovo/inserisci" element={<NewsEdit />} />
            </Route>
            <Route path="vedi-tutte/:categoria?/:guid?" element={<News />} />

            <Route path="news-categorie">
                <Route index={true} element={<NewsCategorie />} />
                <Route path="nuovo/inserisci" element={<NewsCategoriaEdit />} />
                <Route path="edit/:guid?/:seo?" element={<NewsCategoriaEdit />} />
            </Route>

            <Route path="news-macro-categorie">
                <Route index={true} element={<NewsMacroCategorie />} />
                <Route path="nuovo/inserisci" element={<NewsCategoriaEdit />} />
                <Route path="edit/:guid?/:seo?" element={<NewsMacroCategoriaEdit />} />
            </Route>

            <Route path="bilancio">
                <Route index={true} element={<Bilancio />} />
                <Route path="edit/:guid?/:seo?" element={<BilancioEdit />} />
                <Route path="nuovo/inserisci" element={<BilancioEdit />} />
            </Route>

            <Route path="schedulatore">
                <Route index={true} element={<SchedulatoreEdit />} />
                <Route path="history" element={<SchedulatoreHistory />} />
            </Route>

            <Route path="settori" >
                <Route index={true} element={<Settori />} />
                <Route path="edit/:guid?/:seo?" element={<SettoreEdit />} />
                <Route path="nuovo/inserisci" element={<SettoreEdit />} />
            </Route>

            <Route path="logs" element={<Logs />} />

            <Route path="azioni-massive" element={<ImportEdit />} />

            <Route path="manifest" element={<ManifestEdit />} />

            <Route path="tema" element={<ThemeEdit />} />

            <Route path="pagamento" element={<StripePayment />} />
            <Route path="pagamento-ok-carta" element={<StripeOkCarta />} />
            <Route path="pagamento-ok-offline" element={<StripeOkOffline />} />

            <Route path="ricarica" element={<RicaricaStripe />} />

            <Route path="richiestepreventivi" element={<RichiestePrezzo />} />
            <Route path="richiesta-prezzo-dettagli/:sku/:email_cliente" element={<RichiestaPrezzoDettagli />} />

            <Route path="news-tracking" element={<NewsLettura />} />

            <Route path="banners">
                <Route index={true} element={<Banners />} />
                <Route path="edit/:guid?/:seo?" element={<BannerEdit />} />
                <Route path="nuovo/inserisci" element={<BannerEdit />} />
            </Route>

            <Route path="banner-tracking" element={<BannerStats />} />

            <Route path="openinbrowser" element={<OpenInBrowser />} />

            <Route path="mail-templates" element={<MailTemplates />} />
            <Route path="change-password" element={<ChangePassword />} />
            {/*<Route path="login" element={<Login />} />*/}

            <Route path="videos">
                <Route index={true} element={<Videos />} />
                <Route path="edit/:guid?/:seo?" element={<VideoEdit />} />
                <Route path="nuovo/inserisci" element={<VideoEdit />} />
            </Route>

            <Route path="template-ordini">
                <Route index={true} element={<TemplateOrdini />} />
                <Route path="edit/:id" element={<TemplateOrdiniEdit />} />
                <Route path="nuovo/inserisci" element={<TemplateOrdiniEdit />} />
            </Route>
            <Route path="catalogo" element={< Home/>}  />
        </Route>
    )
);

{/* <Route path="" element={}/>
<Route path="" element={}></Route> */}