import React, { useEffect } from 'react';
import { Box, Divider, Modal, Stack, } from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { DbAnagrafica } from "../../Db";
import { useLocation, useParams } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import { getProdottoImmagine, IsNullOrEmpty, isSmallScreen } from "../../utils/Functions";
import { HookIsMobile, UiContainer, UiFabContainer } from "../../utils/Helpers";
import CartProdottiFilters from "./CartProdottiFilters";
import Banner from '../../components/banner/Banner';
import Api from "../../Api";
import { v4 as uuidv4 } from 'uuid';
import CartProdottiAcquista from "./CartProdottiAcquista";
import CartProdottiItem from "./CartProdottiItem";
import CartProdottiItem_WOLF from "./CartProdottiItem_WOLF";
import { ModalRichiediPrezzo } from '../../components/modals/ModalRichiediPrezzo';

export default function CartProdotti(this: { path: string; component: () => JSX.Element; }, props) {
    let { categoria } = useParams<any>();
    let { tipologia } = useParams<any>();
    let { guidsottocategoria } = useParams<any>();
    const [prodotti, setProdotti] = React.useState<any>(null);
    const [prodottiArray, setProdottiArray] = React.useState<any>([]);
    const [fornitori, setFornitori] = React.useState<any>([]);
    const [openTerminiAcquisto] = React.useState(false);
    const [openImage, setOpenImage] = React.useState(false);
    const [arr, setArr] = React.useState<any>(null);
    const [currItem] = React.useState<any>(null);
    const descriptionElementRef = React.useRef<HTMLElement>(null);
    const [anagrafica, setAnagrafica] = React.useState<any>(null);
    const root = useSelector((state: RootStateOrAny) => state);
    const id = uuidv4();
    const [prodotto, setProdotto] = React.useState<any>(null);
    const [schedaProdotto, setSchedaProdotto] = React.useState<any>(null);
    const dispatch = useDispatch();
    const im = HookIsMobile();
    const location = useLocation();
    useEffect(() => {
        if (root.Dorsale && root.Dorsale.layout == "WOLF") {
            setArr([0, 1]);
        } else {
            if (isSmallScreen()) {
                setArr([0, 1, 2]);
            } else {
                setArr([0, 1, 2, 3]);
            }
        }
        (async () => {
            setFornitori((await DbAnagrafica({ ruolo: "FORNITORE", flat: true }, dispatch))?.data);
            setAnagrafica(await Api.Anagrafica.all({ mine: true }, dispatch));
            await reload();
        })();
    }, [])
    useEffect(() => {
        if (prodotti) {
            let tempArr: any = [];
            prodotti?.data?.forEach((item, index) => {
                if (root.Dorsale && root.Dorsale.layout == "WOLF") {
                    if (index % 2 == 0) {
                        const i = index + 2;
                        setProdottiArray({ items: [...prodottiArray, { items: [prodotti.data[i - 2], prodotti.data[i - 1]] }] });
                        tempArr.push({ items: [prodotti.data[i - 2], prodotti.data[i - 1]] });
                    }
                } else {
                    if (!isSmallScreen()) {
                        if (index % 4 == 0) {
                            const i = index + 4;
                            setProdottiArray({ items: [...prodottiArray, { items: [prodotti.data[i - 4], prodotti.data[i - 3], prodotti.data[i - 2], prodotti.data[i - 1]] }] });
                            tempArr.push({ items: [prodotti.data[i - 4], prodotti.data[i - 3], prodotti.data[i - 2], prodotti.data[i - 1]] });
                        }
                    } else {
                        if (index % 3 == 0) {
                            const i = index + 3;
                            setProdottiArray({ items: [...prodottiArray, { items: [prodotti.data[i - 3], prodotti.data[i - 2], prodotti.data[i - 1]] }] });
                            tempArr.push({ items: [prodotti.data[i - 3], prodotti.data[i - 2], prodotti.data[i - 1]] });
                        }
                    }
                }
            }
            )
                ;
            setProdottiArray(tempArr);
        }
    }
        ,
        [prodotti]
    )
    React.useEffect(() => {
        if (openTerminiAcquisto) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openTerminiAcquisto]);

    async function reload() {
        setProdotti(await Api.Prodotto.all({
            /*            take: (props.rows != null ? props.rows * 4 : 14),*/
            take: 40,
            tipologia: tipologia,
            guid_sottocategoria: guidsottocategoria,
            cart: true,
            fav: props.fav ?? false,
            vetrina: location.pathname == "/in-evidenza",
            guid_categoria: categoria
        }, dispatch));
    }

    async function OnFiltersHandler(filters) {
        if (!IsNullOrEmpty(filters)) {
            setProdotti(await Api.Prodotto.all({
                take: (props.rows != null ? props.rows * 4 : 14),
                tipologia: tipologia,
                fav: props.fav ?? false,
                cart: true,
                guid_categoria: categoria,
                guid_sottocategoria: filters.sottocategoria?.guid,
                global: filters.global
            }, dispatch));
        }
    }

    return (
        <Box id={id}>
            {!props.nofilters && location.pathname != "/in-evidenza" &&
                <Banner sx={{ mb: 2 }} />
            }
            <UiContainer>
                {arr && !props.nofilters && location.pathname != "/in-evidenza" &&
                    <CartProdottiFilters categoria={categoria} onFilter={OnFiltersHandler.bind(this)} />
                }
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                >
                    {prodottiArray && prodottiArray && prodottiArray.map((cell, index) => (
                        <Box>
                            <Stack
                                key={index}
                                sx={{}}
                                direction={im ? "column" : "row"}
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={2}
                            >
                                {arr.map((o, i) => {
                                    if (prodottiArray[index].items[i] == null) {
                                        if (im)
                                            return null;
                                        return (
                                            <Box sx={{ width: "100%" }}>
                                            </Box>
                                        );
                                    }
                                    return (
                                        <React.Fragment>
                                            {root.Dorsale && (root.Dorsale.layout == "DEFAULT" || root.Dorsale.layout == null) &&
                                                <CartProdottiItem fornitori={fornitori}
                                                    setSchedaProdotto={setSchedaProdotto}
                                                    setProdotto={setProdotto}
                                                    arr={arr} key={i}
                                                    data={prodottiArray[index].items[i]} />
                                            }
                                            {root.Dorsale && root.Dorsale.layout == "WOLF" &&
                                                <CartProdottiItem_WOLF pIndex={i} prodotti={prodotti}
                                                    p={prodottiArray[index].items[i]}
                                                    fornitori={fornitori}
                                                    setSchedaProdotto={setSchedaProdotto}
                                                    setProdotto={setProdotto}
                                                    arr={arr} key={i}
                                                    data={prodottiArray[index].items[i]} />}
                                        </React.Fragment>
                                    )
                                })}
                            </Stack>
                            {root.Dorsale && root.Dorsale.layout == "WOLF" && index != prodottiArray.length - 1 &&
                                <Divider sx={{ mt: 1 }} />
                            }
                        </Box>
                    ))}
                </Stack>
            </UiContainer>

            {/*IMAGE*/}
            {currItem &&
                <Modal
                    open={openImage}
                    onClose={() => {
                        setOpenImage(false)
                    }}
                >
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: im ? "90vw" : "auto",
                        bgcolor: 'background.paper',
                        p: 2,
                    }}>
                        <CardMedia
                            component="img"
                            height="600"
                            image={getProdottoImmagine(currItem)}
                        />
                    </Box>
                </Modal>
            }
            <ModalRichiediPrezzo anagrafica={anagrafica} />
            <CartProdottiAcquista prodotto={prodotto}
                schedaProdotto={schedaProdotto} onClose={() => {
                    setProdotto(null);
                    setSchedaProdotto(null);
                }} />
            <UiFabContainer onReload={async () => {
                await reload();
            }} />
        </Box>
    )
}
