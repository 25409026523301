import React, {useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {v4 as uuidv4} from 'uuid';
import Moment from "moment";
import {Button, Stack} from "@mui/material";
import Chip from "@mui/material/Chip";
import Api from "../../../../Api";
import {UiContainer} from "../../../../utils/Helpers";


export default function SchedulatoreHistory(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [items, setItems] = React.useState<any>(null);
    const [key, setKey] = React.useState<any>(uuidv4());
    const root = useSelector((state: RootStateOrAny) => state);
    useEffect(() => {
        (async () => {
            setItems((await Api.Schedulatore.head(null, dispatch)));
        })();
    }, [])
    const tableCellStyle = {
        fontWeight: 700,
    };
    const tableRowStyleNoBorder = {
        fontWeight: 700,
        borderBottomWidth: 0,
    };
    const tableCellStyleNoBorder = {
        borderBottomWidth: 0,
    };

    function Row(props: { row: any }) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        return (
            <React.Fragment>
                <TableRow onClick={() => setOpen(!open)} hover={true} selected={open}
                    sx={{
                        cursor: "pointer",
                        borderBottomStyle: "hidden",
                        '& > *': { borderBottom: 'unset' }
                    }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Chip label={Moment(row.data).format('DD/MM/YY HH:mm')} />
                    </TableCell>
                    <TableCell>
                        <Chip label={Moment(row.date_start).format('DD/MM/YY')} />
                    </TableCell>
                    <TableCell>
                        <Chip label={Moment(row.date_end).format('DD/MM/YY')} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ p: 0, textAlign: 'center' }} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {row.hasOrders ? (
                                <Box sx={{ p: 1 }}>
                                    {/*               <Typography variant="h6" gutterBottom component="div">
                                 Dettaglio
                                 </Typography>*/}
                                    {/*                                <Table size="small" aria-label="purchases">
                                 <TableHead>
                                 <TableRow>
                                 <TableCell sx={tableRowStyleNoBorder} align="left">num_ordine</TableCell>
                                 <TableCell sx={tableRowStyleNoBorder} align="left">tipologia</TableCell>
                                 <TableCell sx={tableRowStyleNoBorder} align="left">sku</TableCell>
                                 <TableCell sx={tableRowStyleNoBorder}
                                 align="left">sottocategoria</TableCell>
                                 <TableCell sx={tableRowStyleNoBorder} align="center">quantità</TableCell>
                                 <TableCell sx={tableRowStyleNoBorder} align="right">prezzo</TableCell>
                                 </TableRow>
                                 </TableHead>
                                 <TableBody>
                                 {row.history.map((historyRow) => (
                                 <TableRow onClick={() => {
                                 navigate(`/ordini/edit/${navigateRow.guid}/${historyRow.prodotto_nome}`);
                                 }} hover={true} sx={{cursor: "pointer"}} key={uuidv4()}>
                                 <TableCell sx={tableCellStyleNoBorder} align="left" component="th"
                                 scope="row">
                                 {formatNumOrdine(historyRow.num_ordine)}
                                 </TableCell>
                                 <TableCell sx={tableCellStyleNoBorder}
                                 align="left">{historyRow.tipologia}</TableCell>
                                 <TableCell sx={tableCellStyleNoBorder}
                                 align="left">{historyRow.prodotto_sku}</TableCell>
                                 <TableCell sx={tableCellStyleNoBorder}
                                 align="left">{historyRow.prodotto_sottocategoria}</TableCell>
                                 <TableCell sx={tableCellStyleNoBorder}
                                 align="center">{historyRow.quantita}</TableCell>
                                 <TableCell sx={tableCellStyleNoBorder}
                                 align="right">{currencyFormat(historyRow.prezzo_unitario)}</TableCell>
                                 </TableRow>
                                 ))}
                                 </TableBody>
                                 </Table>*/}
                                    <Box sx={{ mb: 1 }}>
                                        <Button size={"small"} variant={"contained"} color={"secondary"} onClick={() => {
                                            window.open(Api.public.schedulatore_csvincassi_url(row.guid));
                                        }}>
                                            incassi_effettuati_per_periodo.csv
                                        </Button>
                                    </Box>
                                    <Box sx={{ mb: 1 }}>
                                        <Button size={"small"} variant={"contained"} color={"secondary"} onClick={() => {
                                            window.open(Api.public.schedulatore_csvanagraficafornitore_url(row.guid));
                                        }}>
                                            elenco_fornitori.csv
                                        </Button>
                                    </Box>
                                    <Box sx={{ mb: 1 }}>
                                        <Button size={"small"} variant={"contained"} color={"secondary"} onClick={() => {
                                            window.open(Api.public.schedulatore_csvfatturedaemettere_url(row.guid));
                                        }}>
                                            fatture_da_emettere.csv
                                        </Button>
                                    </Box>
                                    <Box sx={{ mb: 1 }}>
                                        <Button size={"small"} variant={"contained"} color={"secondary"} onClick={() => {
                                            window.open(Api.public.schedulatore_csvfatturedariceverecogestori_url(row.guid));
                                        }}>
                                            fatture_da_ricevere_cogestori.csv
                                        </Button>
                                    </Box>
                                </Box>) : (
                                <Typography variant={'body1'} style={{ margin: '20px 0' }} >
                                    Non ci sono ordini associati a questo periodo di tempo
                                </Typography>
                            )}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Box sx={{ pt: 2 }} />
            {items &&
                <UiContainer>
                    <Box
                        sx={{ width: { xs: "90vw", lg: "100%" }, overflowX: { xs: "scroll", lg: "hidden" } }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box sx={{ textAlign: "left", pt: 1, px: 2 }}>
                                {items && items.data && items.data.length > 0 && items.time ? (
                                    <Typography variant={'caption'} sx={{ fontStyle: 'italic' }}>
                                        Trovati {items.tot} risultati in {items.time} millisecondi
                                    </Typography>
                                ) : items && items.data && items.data.length > 0 ? (
                                    <Typography variant={'caption'} sx={{ fontStyle: 'italic' }}>
                                        Trovati {items.tot} risultati
                                    </Typography>
                                ) : (
                                    <Typography variant={'body1'} align="center" sx={{ fontStyle: 'italic' }}>
                                        Non hai alcuno scheduler da visualizzare
                                    </Typography>
                                )}
                            </Box>
                        </Stack>
                        {items && items.data && items.data.length > 0 ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell sx={tableCellStyle}>Data e ora di invio</TableCell>
                                        <TableCell sx={tableCellStyle}>Inizio ordini</TableCell>
                                        <TableCell sx={tableCellStyle}>Fine ordini</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.data.map((row) => (
                                        <Row key={uuidv4()} row={row} />
                                    ))}
                                </TableBody>
                            </Table>
                        ) : null}
                    </Box>
                </UiContainer>
            }
        </React.Fragment>
    );
}

