import React, { useEffect } from 'react';
import {
    Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle,
    ListItemIcon,
    ListSubheader,
    Stack, ThemeProvider
} from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import DescriptionIcon from "@mui/icons-material/Description";
import { useNavigate, useLocation } from "react-router-dom";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { getLabel, Log, reloadNumbers } from "../../utils/Functions";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import RssFeedOutlinedIcon from '@mui/icons-material/RssFeedOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { HookIsMobile } from "../../utils/Helpers";
import { theming } from "../../Theming";
import Menu_DEFAULT from "./Menu_DEFAULT";
import Menu_WOLF from "./Menu_WOLF";

export default function MainMenu(props) {
    const [menu, setMenu] = React.useState<any>(null);
    const [theme, setTheme] = React.useState<any>(null);
    const [scaricaCiclope, setScaricaCiclope] = React.useState<boolean>(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);
    const location = useLocation();
    const im = HookIsMobile();
    let lastisNull = false;
    useEffect(() => {
        if (root.GlobalTheme)
            setTheme(theming(root.GlobalTheme));
    }, [root.GlobalTheme])
    useEffect(() => {
        (async () => {
            if (root?.isLogged == true)
                await reloadNumbers(dispatch);
        })();
    }, [])
    useEffect(() => {
        (async () => {
            if (root.isLogged == true && root.Labels != null) {
                setMenu([
                    {
                        name: "Home",
                        route: "/dashboard",
                        icon: <HomeOutlinedIcon />,
                        role: ["FORNITORE", "CLIENTE", "ADMIN", "COGESTORE", "CITTADINO", "TEMPORANEO"]
                    },
                    {
                        name: `I miei dati (${root.LoginData?.role ?? "GUEST"})`,
                        route: "/i-miei-dati",
                        icon: <AccountCircleOutlinedIcon />,
                        role: ["FORNITORE", "CLIENTE", "ADMIN", "COGESTORE", "CITTADINO"]
                    },
                    null,
                    {
                        name: "Dorsale",
                        icon: <SettingsOutlinedIcon />,
                        role: ["", "", "ADMIN", "COGESTORE", ""],
                        items: [
                            {
                                name: "Configurazione",
                                id: "configurazione",
                                route: "/configurazione-dorsale",
                                role: ["", "", "ADMIN", ""],
                            },
                            {
                                name: "Tema",
                                id: "tema",
                                route: "/tema",
                                role: ["", "", "ADMIN", ""],
                            },
                            {
                                name: "Schedulazione",
                                id: "schedulatore",
                                route: "/schedulatore",
                                role: ["", "", "ADMIN", ""],
                            },
                            {
                                name: "Manifest",
                                id: "manifest",
                                route: "/manifest",
                                role: ["", "", "ADMIN", ""],
                            },
                            {
                                name: "News Macro Categorie",
                                id: "newsmacrocategorie",
                                route: "/news-macro-categorie",
                                icon: <RssFeedOutlinedIcon />,
                                role: ["", "", "ADMIN", "COGESTORE", ""]
                            },
                            {
                                name: "News Categorie",
                                id: "newscategorie",
                                route: "/news-categorie",
                                icon: <RssFeedOutlinedIcon />,
                                role: ["", "", "ADMIN", "COGESTORE", ""]
                            },
                            {
                                name: "News",
                                id: "news",
                                route: "/news",
                                icon: <RssFeedOutlinedIcon />,
                                role: ["", "", "ADMIN", "COGESTORE", ""]
                            },
                            {
                                name: "Lettura News",
                                id: "news-tracking",
                                route: "/news-tracking",
                                icon: <RssFeedOutlinedIcon />,
                                role: ["", "", "ADMIN", "COGESTORE", ""]
                            },
                            {
                                name: "Privacy",
                                id: "privacy",
                                route: "/privacy",
                                icon: <RssFeedOutlinedIcon />,
                                role: ["", "", "ADMIN", "", ""]
                            },
                            {
                                name: "Banners",
                                id: "banner",
                                route: "/banners",
                                role: ["", "", "ADMIN", "", ""]
                            },
                            {
                                name: "Banners Stats",
                                id: "banner-tracking",
                                route: "/banner-tracking",
                                icon: <RssFeedOutlinedIcon />,
                                role: ["", "", "ADMIN", "COGESTORE", ""]
                            },
                            {
                                name: "Condizioni di vendita",
                                id: "condizioni",
                                route: "/condizioni-di-vendita",
                                role: ["", "", "ADMIN", "", ""]
                            },
                            {
                                name: "Azioni Massive",
                                id: "import",
                                route: "/azioni-massive",
                                role: ["", "", "ADMIN", ""],
                            },
                            {
                                name: "Video",
                                id: "video",
                                route: "/videos",
                                role: ["", "", "ADMIN", "", ""]
                            },
                            {
                                name: "Mail Template",
                                id: "mailtemplate",
                                route: "/mail-templates",
                                role: ["", "", "ADMIN", "", ""]
                            },
                            {
                                name: "Mail Template Ordini",
                                id: "mail-templates-ordini",
                                route: "/template-ordini",
                                role: ["", "", "ADMIN", "", ""]
                            },
                            {
                                name: "Report Password",
                                id: "cambiopasswordreport",
                                route: "/cambiopasswordreport",
                                role: ["", "", "ADMIN", "", ""]
                            },
                        ]
                    },
                    null,
                    {
                        name: "Anagrafiche",
                        icon: <FeaturedPlayListOutlinedIcon />,
                        role: ["CLIENTE", "", "ADMIN", "COGESTORE", ""],
                        items: [
                            {
                                id: "admin",
                                name: "Admins",
                                route: "/anagrafiche/admin",
                                role: ["", "", "ADMIN", "", ""],
                            },
                            {
                                id: "cogestore",
                                name: "Co-Gestori",
                                route: "/anagrafiche/cogestore",
                                role: ["", "", "ADMIN", "", ""],
                            },
                            {
                                id: "fornitore",
                                name: "Fornitori",
                                route: "/anagrafiche/fornitore",
                                role: ["", "", "ADMIN", "COGESTORE", ""],
                            },
                            {
                                id: "cliente",
                                name: "Clienti",
                                route: "/anagrafiche/cliente",
                                role: ["", "", "ADMIN", "COGESTORE", ""],
                            },
                            {
                                id: "cittadino",
                                name: "Cittadini",
                                canHide: ["CLIENTE"],
                                route: "/anagrafiche/cittadino",
                                role: ["CLIENTE", "", "ADMIN", "COGESTORE", ""],
                            },
                            {
                                id: "temporaneo",
                                name: "Temporanei",
                                canHide: ["CLIENTE"],
                                route: "/anagrafiche/temporaneo",
                                role: ["CLIENTE", "", "ADMIN", "COGESTORE", ""],
                            },
                        ]
                    },
                    null,
                    // {
                    //    name: "Bilancio",
                    //     id: "bilancio",
                    //     route: "/bilancio",
                    //     icon: <MonetizationOnOutlinedIcon/>,
                    //     role: ["CLIENTE", "FORNITORE", "ADMIN", "COGESTORE", "DIPENDENTE"],
                    //     visible: root.Tipologia != "ENTERPRISE",
                    //  },
                    //   null,
                    {
                        name: "articoli",
                        icon: <LocalGroceryStoreOutlinedIcon />,
                        role: ["FORNITORE", "", "ADMIN", "COGESTORE", ""],
                        items: [
                            {
                                name: "prodotti",
                                route: "/prodotti",
                                id: "prodotto",
                                icon: <DescriptionIcon />,
                                role: ["FORNITORE", "", "ADMIN", "COGESTORE", ""]
                            },
                            {
                                name: "servizi",
                                id: "servizio",
                                route: "/servizi",
                                icon: <DescriptionIcon />,
                                role: ["FORNITORE", "", "ADMIN", "COGESTORE", ""]
                            },
                            //{
                            //    name: "logistica",
                            //    id: "logistica",
                            //    route: "/logistica",
                            //    icon: <DescriptionIcon/>,
                            //    role: ["FORNITORE", "", "ADMIN", "COGESTORE", ""]
                            //},
                            {
                                name: "categorie",
                                id: "categoria",
                                route: "/categoria",
                                role: ["", "", "ADMIN", "COGESTORE", ""]
                            },
                            {
                                name: "sottocategorie",
                                id: "sottocategoria",
                                route: "/sottocategoria",
                                role: ["", "", "ADMIN", "COGESTORE", ""]
                            },
                            {
                                name: "Settore",
                                id: "settore",
                                route: "/settori",
                                role: ["", "", "ADMIN", "COGESTORE", ""]
                            },
                            {
                                id: "richiesta_prezzo",
                                name: "Richieste Preventivi",
                                route: "/richiestepreventivi",
                                role: ["", "", "ADMIN", "COGESTORE", ""]
                            },
                        ]
                    },
                    null,
                    {
                        id: "ordine",
                        name: "Ordini",
                        route: "/ordini",
                        icon: <FeaturedPlayListOutlinedIcon />,
                        role: ["FORNITORE", "CLIENTE", "ADMIN", "COGESTORE", "CITTADINO", "TEMPORANEO"]
                    },
                    null,
                    {
                        name: "catalogo",
                        icon: <MenuBookOutlinedIcon />,
                        role: ["FORNITORE", "CLIENTE", "ADMIN", "COGESTORE", "CITTADINO", "TEMPORANEO"],
                        items: [
                            {
                                id: "cart_prodotto",
                                name: "Prodotti",
                                route: "/catalogo/prodotto",
                                icon: <ShoppingBasketIcon />,
                                role: ["FORNITORE", "CLIENTE", "ADMIN", "COGESTORE", "CITTADINO", "TEMPORANEO"],
                            },
                            {
                                id: "cart_servizio",
                                name: "Servizi",
                                route: "/catalogo/servizio",
                                icon: <ShoppingBasketIcon />,
                                role: ["FORNITORE", "CLIENTE", "ADMIN", "COGESTORE", "CITTADINO", "TEMPORANEO"]
                            },
                            //{
                            //    id: "cart_logistica",
                            //    name: "Logistica",
                            //    route: "/categoria/logistica",
                            //    icon: <ShoppingBasketIcon/>,
                            //    role: ["FORNITORE", "CLIENTE", "ADMIN", "COGESTORE", "CITTADINO", "TEMPORANEO"]
                            //},
                        ]
                    },
                    null,
                    {
                        name: "Report",
                        route: "/",
                        icon: <AssessmentOutlinedIcon />,
                        role: ["", "", "ADMIN", "COGESTORE", ""]
                    },
                ]);
            } else {
                setMenu([
                    {
                        name: "Registrazione",
                        route: "/registrati/cliente",
                        icon: <AssignmentTurnedInIcon />,
                    },
                    {
                        name: "Login",
                        route: "/",
                        icon: <LockOpenIcon />,
                    }]);
            }
        })();
    }, [root.isLogged, root.Labels])
    return (
        <React.Fragment>
            {root.Dorsale && (root.Dorsale.layout == "DEFAULT" || root.Dorsale.layout == null) &&
                <Menu_DEFAULT menu={menu} />}
            {root.Dorsale && root.Dorsale.layout == "WOLF" && <Menu_WOLF menu={menu} />}
        </React.Fragment>
    )
}