import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import {IsNullOrEmptyBool} from "../../utils/Functions";
import {HookIsMobile, UiContainer, UiFrmControl} from "../../utils/Helpers";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    MenuItem
} from "@mui/material";
import {DbComune} from "../../Db";
import {setLoadingMessage} from "../../reducers";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Chip from "@mui/material/Chip";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Api from "../../Api";
import {useLocation, useParams} from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ModalWithTabs from '../../components/modals/ModalWithTabs';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from "@mui/material/IconButton";

const steps = ['Dati Personali', 'Localizzazione', 'Password', 'Privacy'];


export default function StepperRegister() {
    const [showModal, setShowModal] = React.useState(true);
    const [activeStep, setActiveStep] = React.useState(0);
    const [alertStepModal, setAlertStepModal] = React.useState(0);
    const [tipologiacliente, settipologiacliente] = React.useState("privato");
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [anagrafica, setAnagrafica] = React.useState<any>({});
    const [comuni, setComuni] = React.useState<any>(null);
    const [prov, setProv] = React.useState<any>(null);
    const [privacyOk, setPrivacyOk] = React.useState<boolean>(false);
    const nazioni = [{ code: "it", label: "Italia" }, { code: "ee", label: "Estero" }];
    const dispatch = useDispatch();
    const im = HookIsMobile();
    const location = useLocation();
    const root = useSelector((state: RootStateOrAny) => state);
    const [showAlertModal, setShowAlertModal] = React.useState(false);
    const [showAlertModalAccept, setShowAlertModalAccept] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword2) => !prevShowPassword2);
    };
    const [showPassword2, setShowPassword2] = React.useState(false);
    const handleTogglePassword2 = () => {
        setShowPassword2((prevShowPassword2) => !prevShowPassword2);
    };
    const handleCloseModal = () => {
        // Implementa la logica per chiudere la modale (es. impostando lo stato per nascondere la modale)
        setShowModal(false);
    };

    const handleAccettaTutti = () => {
        // Implementa la logica per accettare tutti
        anagrafica.consenso_privacy = true;
        anagrafica.consenso_1 = true;
        anagrafica.consenso_2 = true;
        anagrafica.consenso_3 = true;
        anagrafica.accettazione_termini = true;
        anagrafica.consensoMarketing = true;
        anagrafica.consensoMarketingDaTerzi = true;
        console.log('Accetta Tutti');
        setShowModal(false);
    };

    const handleAccettaSelezionati = (switchValues: boolean[]) => {
        // Implementa la logica per accettare solo i valori selezionati      
        anagrafica.consenso_privacy = switchValues[2];
        anagrafica.consenso_1 = switchValues[2];
        anagrafica.consenso_2 = switchValues[2];
        anagrafica.consenso_3 = switchValues[2];
        anagrafica.accettazione_termini = switchValues[2];
        anagrafica.consensoMarketing = switchValues[0];
        anagrafica.consensoMarketingDaTerzi = switchValues[1];
        console.log('Accetta Selezionati:', switchValues);
        if (!switchValues[0] && !switchValues[1] && alertStepModal == 0) {
            setShowAlertModal(true);
            setShowAlertModalAccept(true);
            setAlertStepModal(1)
        }
        if (!switchValues[0] && !switchValues[1] && showAlertModalAccept === true) {
            setShowModal(false);
        } else {
            setShowModal(false);
        }

    };

    const gestisciClicOk = () => {
        setActiveStep(3);
        setAlertStepModal(2);
        setShowModal(true);
        setShowAlertModal(false); // Chiudi la finestra di dialogo dopo aver aggiornato il valore di activeStep
    };


    let required = true;
    let { email } = useParams<any>();
    let { notify } = useParams<any>();
    let { ruolo } = useParams<any>();
    useEffect(() => {
        (async () => {
            fetch('Json/prov.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    return response.json();
                })
                .then(function (myJson) {
                    setProv((myJson))
                });
        })();


    }, [])


    const isStepOptional = (step: number) => {
        return false;
        return step === 1;
    };
    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };
    const handleChange = (event: any) => {
        setAnagrafica({
            ...anagrafica,
            [event.target.name]: event.target.value,
        });
    };

    function isCittadino() {
        return (ruolo?.toUpperCase() == "CITTADINO" || anagrafica?.ruolo?.toUpperCase() == "CITTADINO");
    }


    const handleNext = async () => {
        const form = document.querySelector("#form") as HTMLFormElement;
        const email = document.querySelector('input[name="email"]') as HTMLInputElement;
        const notify = document.querySelector('input[name="notify"]') as HTMLInputElement;
        const nome_attivita = document.querySelector('input[name="nome_attivita"]') as HTMLInputElement;
        const email_pec = document.querySelector('input[name="email_pec"]') as HTMLInputElement;
        const sdi = document.querySelector('input[name="sdi"]') as HTMLInputElement;
        const piva = document.querySelector('input[name="piva"]') as HTMLInputElement;
        const ragione_sociale = document.querySelector('input[name="ragione_sociale"]') as HTMLInputElement;
        const password2 = document.querySelector('input[name="password2"]') as HTMLInputElement;
        const password = document.querySelector('input[name="password"]') as HTMLInputElement;
        let reportVal = true;
        if (form != null) {
            if (anagrafica.guid == null && email?.value != null && email?.value != "") {
                const check = await Api.Anagrafica.checkemail({ email: email?.value }, dispatch);
                if (!check && ruolo?.toUpperCase() != "TEMPORANEO") {
                    email?.setCustomValidity("Indirizzo email già registrato, sceglierne un altro!!!");
                    form.reportValidity();
                    return false;
                } else {
                    email?.setCustomValidity("");
                    reportVal = form.reportValidity();
                }
            }
            if (notify != null && notify.value === "") {
                notify.setCustomValidity("indicare la email di notifica");
                notify.reportValidity();
                return false;
            }
            if (nome_attivita != null && anagrafica.tipologiacliente == "iva" && nome_attivita.value === "") {
                nome_attivita.setCustomValidity("indicare il campo nome attività");
                nome_attivita.reportValidity();
                return false;
            }
            if (piva != null && anagrafica.tipologiacliente == "iva" && piva.value === "") {
                piva.setCustomValidity("indicare la partita IVA");
                piva.reportValidity();
                return false;
            }
            piva?.setCustomValidity("");
            if (anagrafica.tipologiacliente == "iva" && ragione_sociale != null && anagrafica.tipologiacliente == "iva" && ragione_sociale.value === "") {
                ragione_sociale.setCustomValidity("indicare la ragione sociale");
                ragione_sociale.reportValidity();
                return false;
            }
            ragione_sociale?.setCustomValidity("");
            if (anagrafica.tipologiacliente == "iva" && required && !(sdi == null) && email_pec.value === "" && sdi.value === "") {
                sdi.setCustomValidity("specificare una mail pec valido o in alternativa il campo SDI");
                form.reportValidity();
                return false;
            }
            sdi?.setCustomValidity("");
            if (required && password?.value != null && password?.value != "") {
                const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

                const result = regex.test(password?.value);
                if (!result) {
                    password?.setCustomValidity("la password deve essere di almeno 8 caaratteri, contenere almeno un numero e un carattere speciale e non può contenere il carattere speciale #");
                    form.reportValidity();
                    return false;
                } else
                    password?.setCustomValidity("");
            }
            if (password?.value != password2?.value) {

                password?.setCustomValidity("Le password non coincidono");
                form.reportValidity();
                return false;
            } else
                password?.setCustomValidity("");
            if (reportVal)
                reportVal = form.reportValidity();
        }
        if (!reportVal)
            return false;
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel={im}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Box sx={{ mt: 2 }} />
                    <UiContainer>
                        <Box sx={{ mt: 1 }}>
                            Registrazione avvenuta con successo! Attendi la mail di conferma.
                        </Box>
                    </UiContainer>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{ mt: 2 }}></Box>
                    <UiContainer>
                        {activeStep == 0 &&
                            <form action="#" id="form">
                                <UiFrmControl first>
                                    <RadioGroup
                                        onChange={(event) => {
                                            setAnagrafica({
                                                ...anagrafica,
                                                tipologiacliente: event.target.value,
                                            });
                                            settipologiacliente(event.target.value);
                                        }}
                                        value={tipologiacliente}
                                    >
                                        <FormControlLabel value="privato" control={<Radio />} label="privato" />
                                        <FormControlLabel value="iva" control={<Radio />} label="partita iva" />
                                    </RadioGroup>
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        defaultValue={anagrafica.email ?? email ?? ""}
                                        name="email"
                                        disabled={email != null}
                                        fullWidth
                                        required={required}
                                        label="email"
                                        type="email"
                                        inputProps={{ style: { fontWeight: 700 } }}
                                        onBlur={handleChange}
                                        helperText={"la mail ti servirà come utenza di login e non potrà essere modificata"}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        defaultValue={anagrafica.notify ?? notify ?? ""}
                                        name="notify"
                                        disabled={notify != null}
                                        fullWidth
                                        required={required}
                                        label="notify"
                                        type="email"
                                        inputProps={{ style: { fontWeight: 700 } }}
                                        onBlur={handleChange}
                                        helperText={"la mail verrà utilizzata per inviare le notifiche"}
                                    />
                                </UiFrmControl>
                                {!isCittadino() &&
                                    <React.Fragment>
                                        {anagrafica.tipologiacliente == "iva" &&
                                            <>
                                                <UiFrmControl>
                                                    <TextField
                                                        defaultValue={anagrafica.ragione_sociale ?? ""}
                                                        name="ragione_sociale"
                                                        fullWidth
                                                        label="ragione sociale"
                                                        required={anagrafica.tipologiacliente == "iva"}
                                                        type="text"
                                                        onBlur={handleChange}
                                                    />
                                                </UiFrmControl>
                                                <UiFrmControl>
                                                    <TextField
                                                        defaultValue={anagrafica.nome_attivita ?? ""}
                                                        name="nome_attivita"
                                                        fullWidth
                                                        label="nome attivita"
                                                        required={anagrafica.tipologiacliente == "iva"}
                                                        type="text"
                                                        onBlur={handleChange}
                                                    />
                                                </UiFrmControl>
                                                <UiFrmControl>
                                                    <TextField
                                                        defaultValue={anagrafica.piva ?? ""}
                                                        name="piva"
                                                        fullWidth
                                                        required={anagrafica.tipologiacliente == "iva"}
                                                        label="partita iva"
                                                        inputProps={{
                                                            maxLength: 11,
                                                        }}
                                                        type="text"
                                                        onBlur={handleChange}
                                                    />
                                                </UiFrmControl>
                                                <UiFrmControl>
                                                    <TextField
                                                        defaultValue={anagrafica.email_pec ?? ""}
                                                        name="email_pec"
                                                        required={(IsNullOrEmptyBool(anagrafica.sdi) && IsNullOrEmptyBool(anagrafica.email_pec))}
                                                        fullWidth
                                                        label="email pec"
                                                        type="email"
                                                        onBlur={handleChange}
                                                    />
                                                </UiFrmControl>
                                                <UiFrmControl>
                                                    <TextField
                                                        defaultValue={anagrafica.sdi ?? ""}
                                                        required={(IsNullOrEmptyBool(anagrafica.sdi) && IsNullOrEmptyBool(anagrafica.email_pec))}
                                                        name="sdi"
                                                        fullWidth
                                                        label="sdi"
                                                        inputProps={{
                                                            maxLength: 7,
                                                        }}
                                                        type="text"
                                                        onBlur={handleChange}
                                                    />
                                                </UiFrmControl>
                                            </>
                                        }
                                    </React.Fragment>
                                }

                                <UiFrmControl>
                                    <TextField
                                        required
                                        defaultValue={anagrafica.nome ?? ""}
                                        name="nome"
                                        fullWidth
                                        label="nome"
                                        type="text"
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        defaultValue={anagrafica.cognome ?? ""}
                                        name="cognome"
                                        required
                                        fullWidth
                                        label="cognome"
                                        type="text"
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                {anagrafica.tipologiacliente == "iva" &&
                                    <UiFrmControl>
                                        <TextField
                                            defaultValue={anagrafica.email_referente ?? ""}
                                            name="email_referente"
                                            fullWidth
                                            label="email referente"
                                            type="text"
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                }
                                <UiFrmControl>
                                    <TextField
                                        defaultValue={anagrafica.telefono ?? ""}
                                        name="telefono"
                                        fullWidth
                                        label="telefono"
                                        type="number"
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                            </form>
                        }
                        {activeStep == 1 &&
                            <form action="#" id="form">
                                <UiFrmControl>
                                    <TextField
                                        fullWidth
                                        defaultValue={anagrafica.nazione ?? ""}
                                        name="nazione"
                                        select
                                        required={!isCittadino()}
                                        label="Nazione"
                                        onChange={handleChange}
                                    >
                                        {nazioni.map((option) => (
                                            <MenuItem key={option.code} value={option.code}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </UiFrmControl>
                                {anagrafica.nazione == "it" &&
                                    <React.Fragment>
                                        <UiFrmControl>
                                            <TextField
                                                defaultValue={anagrafica.prov ?? ""}
                                                name="prov"
                                                required={!isCittadino()}
                                                fullWidth
                                                select
                                                label="Provincia"
                                                onChange={async (e) => {
                                                    setComuni(await DbComune(e.target.value, dispatch))
                                                }}
                                                onBlur={handleChange}
                                            >
                                                {prov != null && prov.map((option) => (
                                                    <MenuItem key={option.sigla} value={option.sigla}>
                                                        {option.nome}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </UiFrmControl>
                                        <UiFrmControl>
                                            <TextField
                                                defaultValue={anagrafica.comune ?? ""}
                                                name="comune"
                                                required={!isCittadino()}
                                                select
                                                fullWidth
                                                label="Comune"
                                                onBlur={handleChange}
                                                onChange={(event) => {
                                                    setAnagrafica({
                                                        ...anagrafica,
                                                        cap: comuni.filter(x => x.nome == event.target.value)[0].cap[0],
                                                    });
                                                }}
                                            >
                                                {comuni == null &&
                                                    <MenuItem />
                                                }
                                                {comuni != null && comuni.map((option) => (
                                                    <MenuItem key={option.nome} value={option.nome}>
                                                        {option.nome}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </UiFrmControl>
                                        <UiFrmControl>
                                            <TextField
                                                defaultValue={anagrafica.cap ?? ""}
                                                name="cap"
                                                required={!isCittadino()}
                                                fullWidth
                                                value={anagrafica.cap ?? ""}
                                                inputProps={{
                                                    maxLength: 5,
                                                }}
                                                label="Cap"
                                                onChange={handleChange}
                                            >
                                            </TextField>
                                        </UiFrmControl>
                                        <UiFrmControl>
                                            <TextField
                                                name="indirizzo"
                                                fullWidth
                                                required={!isCittadino()}
                                                autoComplete={"indirizzo"}
                                                label="indirizzo"
                                                type="text"
                                                defaultValue={anagrafica.indirizzo ?? ""}
                                                onBlur={handleChange}
                                            />
                                        </UiFrmControl>
                                        <UiFrmControl>
                                            <TextField
                                                name="civico"
                                                fullWidth
                                                required={!isCittadino()}
                                                autoComplete={"civico"}
                                                label="civico"
                                                type="text"
                                                defaultValue={anagrafica.civico ?? ""}
                                                onBlur={handleChange}
                                            />
                                        </UiFrmControl>
                                    </React.Fragment>
                                }
                            </form>
                        }
                        {activeStep == 2 &&
                            <form action="#" id="form">
                                <UiFrmControl>
                                    <TextField
                                        defaultValue={anagrafica.password ?? ""}
                                        name="password"
                                        fullWidth
                                        required={required}
                                        autoComplete={"current-password"}
                                        label="password"
                                        type={showPassword ? 'text' : 'password'}
                                        onBlur={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleTogglePassword}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        defaultValue={anagrafica.password2 ?? ""}
                                        name="password2"
                                        required={required}
                                        fullWidth
                                        autoComplete={"current-password"}
                                        label="conferma password"
                                        type={showPassword2 ? 'text' : 'password'}
                                        onBlur={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleTogglePassword2}>
                                                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </UiFrmControl>
                            </form>
                        }
                        {activeStep == 3 && showModal &&
                            <React.Fragment>
                                <Chip sx={{ fontWeight: 700, mt: 3 }} label={"privacy accettata correttamente"}
                                    size={"medium"}
                                    color={"default"}></Chip>
                                <ModalWithTabs
                                    onClose={handleCloseModal}
                                    onAccettaTutti={handleAccettaTutti}
                                    onAccettaSelezionati={handleAccettaSelezionati} />

                            </React.Fragment>
                        }
                    </UiContainer>
                    <UiContainer>
                        <Box sx={{ display: 'flex', flexDirection: 'row', py: 3 }}>
                            <Button
                                startIcon={<NavigateBeforeIcon />}
                                variant={"contained"}
                                color={"primary"}
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Indietro
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {(activeStep === steps.length - 1) &&
                                <Button onClick={async () => {
                                    dispatch(setLoadingMessage("registrazione in corso, attendi"));
                                    anagrafica.ruolo = ruolo;
                                    if (email != null)
                                        anagrafica.email = email;
                                    if (await Api.Anagrafica.save(anagrafica, dispatch)) {
                                        dispatch(setLoadingMessage(null));
                                        if (root?.LoginData?.name?.trim("") == "" || root?.LoginData?.name == null) {
                                            if (ruolo?.toUpperCase() == "TEMPORANEO") {
                                                window.location.href = "/db/relogin";
                                            } else {
                                                await handleNext();
                                            }
                                        } else
                                            await handleNext();
                                    } else {
                                        dispatch(setLoadingMessage(null));
                                    }
                                }} variant={"contained"} color={"secondary"}
                                    endIcon={<CheckCircleIcon />}>
                                    Registrati
                                </Button>
                            }
                            {(activeStep !== steps.length - 1) &&
                                <Button onClick={async () => {
                                    await handleNext();
                                }} variant={"contained"} color={"primary"}
                                    endIcon={<NavigateNextIcon />}>
                                    continua
                                </Button>
                            }
                        </Box>
                    </UiContainer>
                </React.Fragment>
            )}
            {alertStepModal == 1 &&
                < Dialog open={showAlertModal}>
                    <DialogTitle>Avviso</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Senza il consenso all’invio di comunicazioni di marketing ti perderai tutte le offerte a te
                            dedicate da parte della dorsale digitale. Potrai comunque modificarle in seguito nel dettaglio del proprio profilo.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={gestisciClicOk}>Ok,ho capito</Button>
                    </DialogActions>
                </Dialog>
            }
        </Box>
    );
}