import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    UiPaper,
    HookIsMobile,
    PageHeader,
    UiContainer
} from "../../../utils/Helpers";
import { Box, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { setLoadingMessage } from "../../../reducers";
import { DbDorsale } from "../../../Db";
import LinearProgress from '@mui/material/LinearProgress';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@mui/material/Typography";
import Api from "../../../Api";

export default React.memo(function ImportEdit() {

    const [item, setItem] = React.useState<any>(null);
    const [loaded, setLoaded] = React.useState<any>(false);
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [fileAnagrafica, setFileAnagrafica] = React.useState<any>(null);
    const [anagraficaErros, setanagraficaErros] = React.useState<any>(null);

    const [file, setFile] = React.useState<any>(null);
    const [result, setResult] = React.useState<any>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();

    useEffect(() => {
        (async () => {
            await reload();
        })();
    }, [])

    async function reload() {
        setItem(await DbDorsale(null, dispatch));
        setLoaded(true);
    }

    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeAccordion =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setFile(null);
            setResult(null);
            setExpanded(newExpanded ? panel : false);
        };

    const Input = styled('input')({
        display: 'none',
    });
    const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    function ImportComponent(props) {
        return (
            <React.Fragment>
                <Box sx={{ mb: 3 }}>
                    <Button variant={"outlined"} color={"secondary"}
                        onClick={() => {
                            window.open(props.url());
                        }}>Scarica template</Button>
                </Box>
                <Box>
                    <Button variant={"contained"} color={"secondary"} onClick={() => {
                        let input = document.createElement('input');
                        input.type = 'file';
                        input.onchange = _ => {
                            if (input != null && input.files != null) {
                                props.setFile(input.files[0]);
                                props.setResult(null);
                            }
                        };
                        input.click();
                    }}>carica file</Button>
                    <Box>
                        {props.file?.name}
                    </Box>
                </Box>
                <Box sx={{ mt: file != null ? 2 : 0 }}>
                    {props.file &&
                        <Button variant={"contained"} color={"secondary"} onClick={async () => {
                            dispatch(setLoadingMessage(props.loadingMessage ? props.loadingMessage : "importazione in corso"));
                            const res = await props.api(null, [
                                {
                                    name: "file",
                                    value: props.file,
                                },
                            ], dispatch)
                            dispatch(setLoadingMessage(null));
                            setResult(res);
                        }}>{props.buttonLabel ? props.buttonLabel : "Inizia Importazione"}</Button>
                    }
                </Box>
                <Box sx={{ mt: result != null ? 2 : 0 }}>
                    {result && result.errors && result.errors.length > 0 &&
                        <Box sx={{ mb: 1, fontWeight: 700 }}>
                            Export interrotto con i seguenti errori. Correggere e riprovare.
                        </Box>
                    }
                    {result && result.errors && result.errors.map((error, i) => {
                        return (
                            <Box sx={{ mb: 1 }}>
                                <Box sx={{ fontSize: 16, fontWeight: 400 }}>{error.item}</Box>
                                <Box sx={{
                                    fontSize: 14,
                                    fontWeight: 700,
                                    color: "error.main"
                                }}>{error.error}</Box>
                            </Box>
                        )
                    })}
                    {result && result.imported > 0 &&
                        <Box sx={{ mb: 1, fontWeight: 700, color: "success.main" }}>
                            Sono stati importati correttamente tutti i {result.imported} record/s
                        </Box>
                    }
                </Box>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {!loaded &&
                <UiContainer>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress color={"secondary"} />
                    </Box>
                </UiContainer>
            }
            <Accordion expanded={expanded === 'import_anagrafica'}
                onChange={handleChangeAccordion('import_anagrafica')}>
                <AccordionSummary>
                    <Typography sx={{ fontWeight: 700 }}>Import Anagrafica (cliente, dipendente, fornitore)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <UiContainer>
                        <PageHeader title={""} />
                        <Box sx={{ fontSize: 12 }}>* email_cliente: indicare la mail del cliente per associare il dipendente al cliente</Box>
                        <UiPaper padding title={""}>
                            <ImportComponent file={file} setFile={setFile} setResult={setResult}
                                api={Api.Import.anagraficatracciatoimport}
                                url={Api.Import.anagraficatracciato_url} />
                        </UiPaper>
                    </UiContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'import_prodotto'}
                onChange={handleChangeAccordion('import_prodotto')}>
                <AccordionSummary>
                    <Typography sx={{ fontWeight: 700 }}>Import Prodotti</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <UiContainer>
                        <PageHeader title={""} />
                        <UiPaper padding title={""}>
                            <ImportComponent file={file} setFile={setFile} setResult={setResult}
                                api={Api.Import.prodottotracciatoimport}
                                url={Api.Import.prodottotracciato_url} />
                        </UiPaper>
                    </UiContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'import_settore'}
                onChange={handleChangeAccordion('import_settore')}>
                <AccordionSummary>
                    <Typography sx={{ fontWeight: 700 }}>Import Settori</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <UiContainer>
                        <PageHeader title={""} />
                        <UiPaper padding title={""}>
                            <ImportComponent file={file} setFile={setFile} setResult={setResult}
                                api={Api.Import.settoretracciatoimport}
                                url={Api.Import.settoretracciato_url} />
                        </UiPaper>
                    </UiContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'invita_cliente'}
                onChange={handleChangeAccordion('invita_cliente')}>
                <AccordionSummary>
                    <Typography sx={{ fontWeight: 700 }}>Invito massivo Cliente</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <UiContainer>
                        <PageHeader title={""} />
                        <UiPaper padding title={""}>
                            <ImportComponent loadingMessage={"invio messaggi in corso"} buttonLabel={"Avvia"}
                                file={file} setFile={setFile} setResult={setResult}
                                api={Api.Import.invitomassivo}
                                url={Api.Import.invitomassivotracciato_url} />
                        </UiPaper>
                    </UiContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'invita_dipendente'}
                onChange={handleChangeAccordion('invita_dipendente')}>
                <AccordionSummary>
                    <Typography sx={{ fontWeight: 700 }}>Invito massivo Dipendente</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <UiContainer>
                        <PageHeader title={""} />
                        <UiPaper padding title={""}>
                            <ImportComponent loadingMessage={"invio messaggi in corso"} buttonLabel={"Avvia"}
                                file={file} setFile={setFile} setResult={setResult}
                                api={Api.Import.invitomassivodipendente}
                                url={Api.Import.invitomassivotracciato_url} />
                        </UiPaper>
                    </UiContainer>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
})