import React, { createRef, useRef, useState } from 'react';
import { Box, Button, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import { v4 as uuidv4 } from 'uuid';
import { downloadMedia } from "./Functions";
import Grid from "@mui/material/Grid";
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
import { TextTitle } from "./Text";

export const HookIsMobile = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("sm"))
}

export function UiContainer(props) {
    const im = HookIsMobile();
    return (
        <Box className={(props.hideBox || im) ? "" : "box_padding box box_mt box_mb"} {...props}
            sx={{ px: im ? 2 : 0, pb: im ? 0 : 0 }}>
            {props.children}
        </Box>
    );
}

export function UiPaper(props) {
    const im = HookIsMobile();
    return (
        <Box {...props} sx={{
            mt: props.first ? 0 : 2,
            display: props.inline != null ? "inline-block" : "block"
        }}>
            {/*            {props.title &&
            <Typography variant="subtitle2"
                        sx={{
                            fontWeight: 900,
                            textTransform: "uppercase",
                            pl: 1,
                            pb: 1
                        }}>{props.title}</Typography>
            }*/}
            {props.title &&
                <Paper elevation={im ? 0 : 0} sx={{
                    mb: 2,
                    p: im ? 2 : 0,
                    /*                position: "relative",
                                    top: im ? 0 : 0,
                                    pt: im ? 1 : 1,
                                    pb: im ? 1 : 2,
                                    px: im ? 2 : 2,
                                    mb: im ? 2 : 0,
                                    display: "table"*/
                }}>
                    <TextTitle>
                        {props.title}
                    </TextTitle>
                </Paper>
            }
            <Paper elevation={im ? 0 : 0} sx={{ position: "relative", p: im ? 1 : props.padding ? 0 : 0 }}>
                {props.children}
            </Paper>
        </Box>);
}

export function PageHeader(props) {
    const im = HookIsMobile();
    if (props.title != null && props.title != "")
        return (
            <Box sx={{ mb: im ? 3 : 2 }}>
                <Typography variant="h4">{props.title}</Typography>
            </Box>
        );
    return null;
}

export function UiFrmControl(props) {
    return (
        <Box {...props} sx={{ mt: props.first ? 0 : 3 }}>
            {props.children}
        </Box>
    );
}

export function UiFabContainer(props) {
    return (
        <Box sx={{ position: "fixed", right: 0, bottom: 10, m: { xs: 1, lg: 2 } }}>
            <Stack spacing={2}
                justifyContent="center"
                alignItems="center">
                {props.onReload &&
                    <Fab color="default" size="small"
                        onClick={async () => {
                            await props.onReload();
                        }}>
                        <RefreshIcon />
                    </Fab>
                }
                <Fab color="default" size="small"
                    onClick={() => {
                        window.history.back();
                    }}>
                    <ArrowBackIcon />
                </Fab>
                {props.children}
            </Stack>

        </Box>
    )
}

export function UiSkeletonForm(props) {
    return (
        <Box>
            <Skeleton sx={{ mb: 2 }} animation="wave" variant="circular" width={40} height={40} />
            <Skeleton sx={{ mb: 2 }} animation="wave" variant="rectangular" width="100%"
                height={100} />
            <Skeleton sx={{ mb: 2 }} animation="wave" variant="rectangular" width="100%"
                height={300} />
            <Skeleton sx={{ mb: 2 }} animation="wave" variant="rectangular" width="100%"
                height={100} />
        </Box>
    )
}

export function UiSkeletonGrid(props) {
    return (
        <Skeleton sx={{ mb: 2 }} animation="wave" variant="rectangular" width="100%"
            height={400} />
    )
}

export function UiSaveButton(props) {
    return (
        <SaveIcon color={"secondary"} />
    )
}

export interface UiFileUploaderProps {
    onChange: any;
    onDelete?: any;
    media?: any;
    image?: boolean;
    label?: string;
}

export function UiColorPicker(props) {
    const id = uuidv4();
    return (
        <Stack spacing={2} direction={"row"} alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
            <TextField
                name={props.name}
                label={props.label}
                fullWidth
                type="text"
                defaultValue={props.defaultValue}
                onBlur={props.onBlur}
                onChange={(e) => {
                    (document.getElementById(id) as HTMLDivElement).style.backgroundColor = e.target.value;
                }}
            />
            <Box id={id} sx={{ width: 50, height: 50, backgroundColor: props.defaultValue }} />
        </Stack>
    );
}
export function UiFileUploader(props: UiFileUploaderProps) {
    const image_id = uuidv4();
    const im = HookIsMobile();
    const textRef = useRef();
    return (
        <Grid sx={{mb: 0}} container alignItems={"center"} justifyContent={"center"}
              alignContent={"center"}
              spacing={2}>
            <Grid item xs={12} lg={12}>
                <Button variant={"contained"} color={"secondary"} onClick={() => {
                    let input = document.createElement('input');
                    let imageInput = (document.getElementById(`${image_id}`) as any);
                    input.type = 'file';
                    input.onchange = _ => {
                        if (input != null && input.files != null) {
                            console.log(input.files);
                            props.onChange(input.files[0]);
                            if (props.image && imageInput) {
                                imageInput.src = URL.createObjectURL(input.files[0]);
                            } else if (!props.image) {
                                (textRef.current as any).value = input.files[0].name;
                            }
                        }
                    };
                    input.click();
                }}>{props.label ?? "carica file"}</Button>
            </Grid>
            {!props.image &&
                <Grid item xs={12} lg={12}>
                    <TextField
                        inputRef={textRef}
                        disabled
                        label=""
                        fullWidth
                        type="text"
                        value={props?.media?.filename ?? ""}
                    />
                </Grid>
            }
            {props?.media?.guid && !props.image &&
                <Grid item xs={12} lg={12}>
                    <Button variant={"contained"} onClick={() => {
                        downloadMedia(props?.media?.guid);
                    }}>
                        preview
                    </Button>
                </Grid>
            }
            {props?.media?.guid && props.onDelete &&
                <Grid item xs={12} lg={12}>
                    <Button variant={"contained"} onClick={() => {
                        if (!window.confirm("Confermi?"))
                            return false;
                        if (!props.image) {
                            (textRef.current as any).value = "";
                            (textRef.current as any).value = "";
                        }
                        props.onDelete(props?.media?.guid);
                        if (props.image)
                            (document.getElementById(`${image_id}`) as any).src = "";
                    }}>
                        elimina
                    </Button>
                </Grid>
            }
            {props.image &&
                <Box sx={{textAlign: im ? "center" : "left", width: "100%"}}>
                    <img id={image_id}
                         src={props?.media?.guid ? `/db/media/get?guid=${props?.media?.guid}` : ``}
                         style={{
                             marginTop: "1rem",
                             marginLeft: "1rem",
                             maxWidth: im ? "80vw" : 300,
                         }}/>
                </Box>
            }
        </Grid>
    )
}
export function UiFileListUploader(props: UiFileUploaderProps) {
    const image_id = uuidv4();
    const im = HookIsMobile();
    const textRef = useRef();
    return (
        <Grid sx={{ mb: 0 }} container alignItems={"center"} justifyContent={"flex-start"}
            alignContent={"center"}
            spacing={2}>
            <Grid item xs={12} lg={12}>
                <Button variant={"contained"} color={"secondary"} onClick={() => {
                    let imageInput = (document.getElementById(`${image_id}`) as any);
                    let input = document.createElement('input');
                    input.type = 'file';
                    input.onchange = _ => {
                        if (input != null && input.files != null) {
                            props.onChange({
                                contenttype: input.files[0].type,
                                filename: input.files[0].name,
                                src: URL.createObjectURL(input.files[0]),
                                data: input.files[0]
                            });
                        }
                    };
                    input.click();
                }}>{props.label ?? "carica file"}</Button>
            </Grid>
            {!props.image &&
                <Grid item xs={12} lg={12}>
                    <TextField
                        inputRef={textRef}
                        disabled
                        label=""
                        fullWidth
                        type="text"
                        value={props?.media?.filename ?? ""}
                    />
                </Grid>
            }
            <Box sx={{ display: 'flex', flexWrap: im ? 'wrap' : 'nowrap' }}>
                {props.image && props?.media.map((image) => {
                    return (
                        <Box aria-label='display image' sx={{
                            textAlign: im ? "center" : "left", width: "100%", marginTop: "1rem",
                            marginLeft: "1rem", position: 'relative'
                        }}>
                            <img
                                id={image.id || image_id}
                                key={image.id || image_id}
                                src={image?.guid
                                    ? `/db/media/get?guid=${image.guid}`
                                    : image.src
                                    // : URL.createObjectURL(image)
                                }
                                style={{
                                    maxWidth: im ? "80vw" : 300,
                                    maxHeight: im ? "80vh" : 300
                                }} />
                            <IconButton
                                sx={{
                                    borderRadius: 10,
                                    backgroundColor: red[500], // Imposta il colore di sfondo trasparente per iniziare
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    "&:hover": {
                                        backgroundColor: red[500], // Imposta il colore di sfondo trasparente per iniziare
                                    }
                                }}>
                                <DeleteIcon
                                    sx={{
                                        color: 'white',
                                        "&:hover": {
                                            color: 'white',
                                        },
                                    }}
                                    onClick={() => {
                                        props.onDelete(image);
                                    }}
                                />
                            </IconButton>
                        </Box>
                    )
                })}
            </Box>
        </Grid>
    )
}

export function UiFormInfo(props: any) {
    /*    if (props.item?.data_ins)
            return (
                <UiPaper {...props} title={"info"}>
                    {props.item?.data_ins &&
                        <UiFrmControl first>
                            <TextField
                                name="data_ins"
                                label="data_ins"
                                inputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                type="datetime-local"
                                defaultValue={props.item?.data_ins ?? new Date()}
                            />
                        </UiFrmControl>
                    }
                    {props.item?.data_upd &&
                        <UiFrmControl>
                            <TextField
                                name="data_upd"
                                label="data_upd"
                                inputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                type="datetime-local"
                                defaultValue={props.item?.data_upd ?? new Date()}
                            />
                        </UiFrmControl>
                    }
                </UiPaper>
            );*/
    return null;
}