import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, CircularProgress, InputAdornment, Popper, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import Api from "../Api";
import { HookIsMobile } from "../utils/Helpers";
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from 'uuid';
import Avatar from "@mui/material/Avatar";
import CartProdottiAcquista from '../pages/catalogo/CartProdottiAcquista';
import { HomeProdottiItem } from '../pages/home/HomeProdottti';
import { TextDescription, TextNormal } from "../utils/Text";

export function SearchAll() {
    useNavigate();

    const [prodotti, setProdotti] = React.useState<any>([]);
    const [, setResultsOpen] = React.useState(false);
    const [resultsLoading, setResultsLoading] = React.useState(false);
    const [prodottiLabel] = React.useState<any>("cerca");
    const [searchText, setSearchText] = React.useState<any>("");
    const [prodotto, setProdotto] = React.useState<any>(null);
    const [schedaProdotto, setSchedaProdotto] = React.useState<any>(null);
    HookIsMobile();
    const dispatch = useDispatch();

    const id = uuidv4();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            if (searchText != "" && searchText != null) {
                setResultsLoading(true);
                const res = await Api.Search.all({ text: searchText }, dispatch);
                setProdotti(res ?? []);
                setResultsLoading(false);
                setAnchorEl(document.getElementById("globalSearchTextField"));
            }
        }, 300);
        return () => clearTimeout(timeoutId);
    }, [searchText]);

    return (
        <React.Fragment>
            <TextField
                size={"small"}
                sx={{ ml: 6, width: 300 }}
                id={"globalSearchTextField"}
                type="text"
                placeholder={"cerca prodotti o servizi"}
                InputProps={{
                    className: "small_input",
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                onKeyDown={(e) => {
                    setSearchText((e.target as any).value);
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                variant={"menu"}
            >
                <Box sx={{ px: 1, maxWidth: 520, maxHeight: 460 }}>
                    <Stack direction={"column"} alignItems={"stretch"} justifyContent={"stretch"}
                        alignContent={"stretch"} spacing={1}>
                        {prodotti && !prodotti.data &&
                            <TextNormal>
                                nessun risultato
                            </TextNormal>
                        }
                        {prodotti && prodotti.data && prodotti.data.length > 0 && prodotti.data.map((p, pIndex) => {
                            return (
                                <HomeProdottiItem
                                    pIndex={pIndex}
                                    onZoom={(p) => {
                                        setSchedaProdotto(p);
                                    }}
                                    onAddToCart={(p) => {
                                        setProdotto(p);
                                    }}
                                    prodotti={prodotti}
                                    prodotto={p} />
                                /* <div style={{display: "table-row"}}>
                                     <div style={{display: "table-cell", whiteSpace: "nowrap"}}>
                                         <Avatar variant="square"
                                                 sx={{width: 40, height: 40}}
                                                 src={`/db/media/get?guid=${r.guid_immagine}`}/>
                                     </div>
                                     <div style={{display: "table-cell", whiteSpace: "nowrap"}}>
                                         {r.type}
                                     </div>
                                     <div style={{display: "table-cell", whiteSpace: "nowrap"}}>
                                         {r.label}
                                     </div>
                                 </div>*/
                            )
                        })
                        }
                    </Stack>
                </Box>
            </Menu>
            <CartProdottiAcquista prodotto={prodotto} schedaProdotto={schedaProdotto} onClose={() => {
                setProdotto(null);
                setSchedaProdotto(null);
            }} />
        </React.Fragment>
    )
}