import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { currencyFormat, truncate } from "../utils/Functions";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Autocomplete, Box, MenuItem, Typography } from "@mui/material";
import { HookIsMobile, UiContainer, UiFabContainer, UiFrmControl, UiSkeletonGrid } from "../utils/Helpers";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import Moment from "moment";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import { SetNumbers, setRootFilters } from "../reducers";
import DatePicker from '@mui/lab/DatePicker';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import Banner from "./banner/Banner";
import { TextNormaBold } from "../utils/Text";



export interface BaseGridHeaders {
    id: string,
    label: string,
    type?: string,
    chip?: boolean,
    check?: boolean,
    bold?: boolean,
    width?: string,
    options?: any,
    onChange?: any,
    mobile?: boolean,
    primary?: boolean,
    date?: boolean,
    currency?: boolean,
    disabled?: boolean,
    hide?: boolean,
}



export interface BaseGridProps {
    getDataSourceAsync?: any;
    onRowClick?: any;
    onNew?: any;
    hideMod?: boolean,
    id?: string,
    filtersDefault?: any;
    overrideRootFilter?: boolean;
    disableAdd?: boolean;
    ruolo?: string,
    exportUrl?: string;
    headers: Array<BaseGridHeaders>;
    filters?: Array<Array<BaseGridHeaders>>;
}



export function BaseGrid(props: BaseGridProps) {
    const [filters, setfilters] = React.useState<any>({});
    const [items, setItems] = React.useState<any>(null);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<any>('nome');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [top, setTop] = React.useState<any>(20);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();
    const root = useSelector((state: RootStateOrAny) => state);
    useEffect(() => {
        if (props.overrideRootFilter == null && root.Filters != null) {
            setfilters(root.Filters)
        } else if (props.filtersDefault) {
            setfilters(props.filtersDefault)
        }
    }, [])
    useEffect(() => {
        (async () => {
            if (props.filtersDefault && Object.keys(filters).length == 0)
                return;
            dispatch(setRootFilters(filters));
            await reload();
        })();
    }, [filters])
    useEffect(() => {
        if (items && props.id)
            dispatch(SetNumbers({ id: props.id, value: items.tot }));
    }, [items])
    let obj = {};



    async function reload(take?: any, sort?: string) {
        setItems(null);
        obj = {};
        if (props.filters != null) {
            props.filters.map((o1, i1) => {
                if (o1 != null)
                    o1.map((o, i) => {
                        if (filters)
                            if (o.type == "date")
                                obj[o.id] = filters[o.id] != null ? Moment(filters[o.id]).format() : "";
                            else
                                obj[o.id] = filters[o.id];
                    });
            });
        }
        obj["take"] = take ?? top;
        obj["sort"] = sort ?? "";
        setItems(await props.getDataSourceAsync(obj, dispatch));
    }



    type Order = 'asc' | 'desc';



    function EnhancedTableHead(props: any) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler =
            (property) => (event) => {
                onRequestSort(event, property);
            };
        return (
            <TableHead>
                <TableRow>
                    <TableCell
                        align={"left"}
                        padding={'normal'}
                    >
                    </TableCell>
                    {filterHeaders(props.headers).map((headCell) => (
                        <TableCell
                            key={uuidv4()}
                            align={"left"}
                            padding={'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    {!props.hideMod && !im &&
                        <TableCell
                            sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                            align={"right"}
                            padding={'normal'}
                        >
                            mod
                        </TableCell>
                    }
                </TableRow>
            </TableHead>
        );
    }



    function filterHeaders(dataSource: any) {
        return im ? dataSource.filter(x => x.mobile) : dataSource;
    }



    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = items.data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const handleChange = (event: any) => {
        setfilters({
            ...filters,
            [event.target.name]: event.target.value,
        });
    };
    const useStyles = makeStyles(theme => ({
        input: {
            fontWeight: 700,
            textTransform: "uppercase"
        },
        paper: {
            textTransform: "uppercase"
        }
    }));
    const classes = useStyles();
    function descendingComparator(a, b, orderBy) {
        const aValue = (a[orderBy] || '').toLowerCase();
        const bValue = (b[orderBy] || '').toLowerCase();
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
    }



    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)
    }



    function Filters() {
        return <>
            <Box
                component="form" sx={{ mt: 0, mb: im ? 0 : 0 }}
            >
                <UiFrmControl first>
                    <Stack spacing={2}
                        direction={"column"}
                        justifyContent="start"
                        sx={{ width: "100%" }}
                        alignItems="start">
                        {props.filters && props.filters.map((filter1, index1) => {
                            return (
                                <Stack spacing={2}
                                    key={uuidv4()}
                                    direction={im ? "column" : "row"}
                                    justifyContent="start"
                                    sx={{ width: "100%" }}
                                    alignItems="start">
                                    {filter1.map((filter, index) => {
                                        if (filter.hide == true)
                                            return null;
                                        if (filter.options) {
                                            if (!Array.isArray(filter.options)) {
                                                return null;
                                            }
                                            return (
                                                <Autocomplete
                                                    key={uuidv4()}
                                                    fullWidth
                                                    disabled={filter.disabled ? filter.disabled : false}
                                                    classes={classes}
                                                    id={filter.id}
                                                    options={filter.options}
                                                    getOptionLabel={(option: any) => option as any}
                                                    value={(filters != null && filters[filter.id]) ? filters[filter.id] : ""}
                                                    onChange={(_, newValue) => {
                                                        if (filter.onChange)
                                                            filter.onChange(newValue);
                                                        setfilters({
                                                            ...filters,
                                                            [filter.id]: newValue,
                                                        });
                                                    }}
                                                    filterSelectedOptions
                                                    renderInput={(params) => {
                                                        return (
                                                            <TextField {...params}
                                                                variant="outlined"
                                                                placeholder={filter.label} />
                                                        );
                                                    }}
                                                />
                                            );
                                        }
                                        if (filter.type == "date") {
                                            return (
                                                <DatePicker
                                                    label={filter.label}
                                                    key={uuidv4()}
                                                    value={(filters != null && filters[filter.id] != null) ? filters[filter.id] : null}
                                                    onChange={(newValue) => {
                                                        setfilters({
                                                            ...filters,
                                                            [filter.id]: newValue,
                                                        });
                                                    }}
                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                />
                                            );
                                        }
                                        return (
                                            <TextField
                                                inputProps={{
                                                    onKeyDown: (event: any) => {
                                                        if (event.key === 'Enter') {
                                                            handleChange(event);
                                                        }
                                                    },
                                                }}
                                                fullWidth
                                                key={uuidv4()}
                                                name={filter.id}
                                                onBlur={handleChange}
                                                defaultValue={(filters != null && filters[filter.id]) ? filters[filter.id] : ""}
                                                label={filter.label}
                                                variant="outlined" />
                                        );
                                    })}
                                </Stack>)
                        }
                        )}
                    </Stack>
                </UiFrmControl>
                <Button sx={{ display: "none" }} type="submit" variant="text"
                    onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }}
                >Cerca</Button>
            </Box>
        </>;
    }



    let showFilters = false;
    props?.filters?.map
        (
            (f, i) => {
                f.map((f1, i1) => {
                    if (f1.hide == false || f1.hide == null)
                        showFilters = true;
                })
            }
        )
    return (
        <React.Fragment>
            {showFilters &&
                <UiContainer>
                    <Filters />
                </UiContainer>
            }
            <Box sx={{ mb: im ? 0 : 0, mt: im ? 2 : 0 }}>
                <Banner />
            </Box>
            <Box className={"box box_padding box_mb box_mt"}>
                {!items?.data &&
                    <Box sx={{ p: 2 }}>
                        <TextNormaBold>Nessun dato</TextNormaBold>
                    </Box>
                }
                {items?.data != null &&
                    <Stack direction={"column"}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box sx={{ textAlign: "left", pt: 1, px: 2 }}>
                                {items.time &&
                                    <Typography variant={"caption"} sx={{ fontStyle: "italic" }}>
                                        Trovati {items.tot} risultati in {items.time} millisecondi
                                    </Typography>
                                }
                                {!items.time &&
                                    <Typography variant={"caption"} sx={{ fontStyle: "italic" }}>
                                        Trovati {items.tot} risultati
                                    </Typography>
                                }
                            </Box>
                            <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                                <TextField
                                    select
                                    size={"small"}
                                    name="length"
                                    type={"number"}
                                    value={top}
                                    onChange={async (e) => {
                                        setTop(e.target.value);
                                        await reload(e.target.value);
                                    }}
                                    variant="outlined">
                                    {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 1000].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option == 1000 ? "tutti" : option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {props.exportUrl &&
                                    <Tooltip title="esporta i dati correnti">
                                        <IconButton sx={{ m: 1 }} color="secondary" onClick={() => {
                                            var str = "";
                                            for (var key in filters) {
                                                if (str != "") {
                                                    str += "&";
                                                }
                                                str += key + "=" + encodeURIComponent(filters[key]);
                                            }
                                            str += "&export=true";
                                            window.open(props.exportUrl + "?" + str);
                                        }}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Stack>
                        </Stack>
                        <TableContainer sx={{ width: "100%" }}>
                            <Table sx={{ width: "100%" }}>
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    hideMod={props.hideMod}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={items.data.length}
                                    headers={filterHeaders(props.headers)}
                                />
                                <TableBody>
                                    {items.data.slice().sort(getComparator(order, orderBy)).map((row, index) => (
                                        <TableRow
                                            onClick={() => {
                                                props.onRowClick(row);
                                            }}
                                            hover
                                            key={uuidv4()}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                cursor: "pointer"
                                            }}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            {filterHeaders(props.headers).map((cell, indexCell) => {
                                                if (cell.chip == true)
                                                    return (
                                                        <TableCell key={indexCell} scope="row">
                                                            <Chip
                                                                /*color={cell.primary ? "secondary" : "default"}*/
                                                                color={"default"}
                                                                sx={{ fontWeight: (cell.primary || cell.bold) == true ? 700 : 400 }}
                                                                label={row[cell.id]} />
                                                        </TableCell>
                                                    );
                                                if (cell.check == true)
                                                    return (
                                                        <TableCell key={indexCell} align="left"
                                                            sx={{ width: 50 }}>
                                                            <Checkbox disabled={true}
                                                                checked={row[cell.id] ?? false} />
                                                        </TableCell>
                                                    );
                                                if (cell.date == true)
                                                    return (
                                                        <TableCell key={indexCell}
                                                            align="left">{Moment(row[cell.id]).format('DD/MM/YY HH:mm')}</TableCell>
                                                    );
                                                if (cell.date == true)
                                                    return (
                                                        <TableCell key={indexCell}
                                                            sx={{ width: cell.width ? cell.width : "auto" }}
                                                            align="left">{row[cell.id]}</TableCell>
                                                    );
                                                if (cell.currency == true)
                                                    return (
                                                        <TableCell key={indexCell}
                                                            sx={{ width: cell.width ? cell.width : "auto" }}
                                                            align="left">{currencyFormat(row[cell.id])}</TableCell>
                                                    );
                                                return (
                                                    <TableCell key={indexCell}
                                                        sx={{
                                                            fontWeight: (cell.primary || cell.bold) == true ? 700 : 400,
                                                            width: cell.width ? cell.width : "auto"
                                                        }}
                                                        align="left">{truncate(row[cell.id], 120)}</TableCell>
                                                );
                                            })}
                                            {/*                   {im && !props.hideMod &&
<TableCell
                                             sx={{whiteSpace: "nowrap"}}
                                             align="right">{Moment(row["data_upd"]).format('DD/MM/YY')}</TableCell>
                                             }*/}
                                            {!im && !props.hideMod &&
                                                <TableCell
                                                    sx={{ whiteSpace: "nowrap" }}
                                                    align="right">{Moment(row["data_upd"]).format('DD/MM/YY HH:mm')}</TableCell>
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                }
                {!items &&
                    <UiSkeletonGrid />
                }
            </Box>
            <UiFabContainer>
                <Fab color="primary" size={"small"}
                    onClick={async () => {
                        await reload();
                    }}>
                    <RefreshIcon />
                </Fab>
                {props.onNew && !props.disableAdd && root.LoginData.role != 'FORNITORE' &&
                    <Fab sx={{ borderRadius: `5 !important` }} color="secondary" onClick={() => {
                        props.onNew();
                    }}>
                        <AddIcon />
                    </Fab>
                }
            </UiFabContainer>
        </ React.Fragment>
    );
}