import React, {useEffect} from 'react';
import {DbRuolo} from "../../Db";
import {BaseGrid} from '../../components/BaseGrid';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Api from "../../Api";
import {useNavigate} from "react-router-dom";
import { useParams } from "react-router-dom";

export default function Anagrafiche(props) {

    const navigate = useNavigate();
    const [ruoli, setRuoli] = React.useState<any>(null);
    const root = useSelector((state: RootStateOrAny) => state);

    const dispatch = useDispatch();

    let { ruolo } = useParams<any>();

    useEffect(() => {
        (async () => {
            setRuoli(await DbRuolo(dispatch));
        })();
    }, [])

    // useEffect(() => {
    // }, [ruolo])

    return (
        <React.Fragment>
            {ruoli && ruolo &&
                <BaseGrid
                    key={ruolo}
                    id={"anagrafiche"}
                    exportUrl={"/db/anagrafica/all"}
                    filtersDefault={{ ruolo: ruolo }}
                    disableAdd={(root.LoginData.role != "ADMIN" && root.LoginData.role != "COGESTORE")}
                    getDataSourceAsync={Api.Anagrafica.all}
                    onRowClick={(row) => {
                        navigate(`/anagrafiche/${row.ruolo}/${row.guid}/PROFILO`);
                    }}
                    onNew={() => {
                        navigate(`/anagrafiche/${ruolo}/inserisci`);
                    }}
                    headers={[
                        {
                            id: 'ragione_sociale',
                            label: 'ragione sociale',
                            chip: true,
                            bold: true,
                            mobile: true
                        },
                        {
                            id: 'email',
                            label: 'email',
                        },
                        {
                            id: 'nome',
                            label: 'nome',
                        },
                        {
                            id: 'cognome',
                            label: 'cognome',
                        },
                    ]}
                    filters={[[
                        {
                            id: 'global',
                            label: 'cerca',
                        },
                        {
                            id: 'ruolo',
                            label: 'ruolo',
                            options: ruoli.map(x => x.nome),
                            disabled: true,
                        },
                    ]]}
                />
            }
        </React.Fragment>)
}