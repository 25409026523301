import React, {useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Api from "../../Api";
import {v4 as uuidv4} from 'uuid';
import Moment from "moment";
import {currencyFormat, downloadMedia, formatNumOrdine} from "../../utils/Functions";
import Grid from "@mui/material/Grid";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import {Button, Stack} from "@mui/material";
import {UiContainer} from "../../utils/Helpers";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import Chip from "@mui/material/Chip";
import {setLoadingMessage} from "../../reducers";

export default function Ordini(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [items, setItems] = React.useState<any>(null);
    const [filters, setFilters] = React.useState<any>(null);
    const [key, setKey] = React.useState<any>(uuidv4());
    const root = useSelector((state: RootStateOrAny) => state);

    async function reload() {
        setItems((await Api.Ordine.head(filters, dispatch)));
    }

    useEffect(() => {
        (async () => {
            await reload();
        })();
    }, [filters])

    function Filters(props) {
        return (
            <React.Fragment>
                <Grid sx={{ mb: 2 }} container alignItems={"stretch"} justifyContent={"stretch"}
                    alignContent={"stretch"}
                    spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            name="cliente"
                            label="cliente"
                            fullWidth
                            type="text"
                            defaultValue={filters?.cliente ?? ""}
                            onBlur={(e) => {
                                setFilters({
                                    ...filters,
                                    cliente: e?.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            name="fornitore"
                            label="fornitore"
                            fullWidth
                            type="text"
                            defaultValue={filters?.fornitore ?? ""}
                            onBlur={(e) => {
                                setFilters({
                                    ...filters,
                                    fornitore: e?.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            name="sku"
                            label="prodotto"
                            fullWidth
                            type="text"
                            defaultValue={filters?.sku ?? ""}
                            onBlur={(e) => {
                                setFilters({
                                    ...filters,
                                    sku: e?.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <DatePicker
                            label="data inizio"
                            value={filters?.dataInizio ?? null}
                            onChange={(newValue) => {
                                setFilters({
                                    ...filters,
                                    dataInizio: Moment(newValue).format(),
                                });
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <DatePicker
                            label="data fine"
                            value={filters?.dataFine ?? null}
                            onChange={(newValue) => {
                                setFilters({
                                    ...filters,
                                    dataFine: Moment(newValue).format(),
                                });
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                </Grid>
                <Stack spacing={2}
                    direction={"row"}
                    justifyContent="start"
                    alignItems="center">
                    <Button variant={"contained"} color={"secondary"}>cerca</Button>
                    <Button variant={"contained"} color={"secondary"} onClick={() => {
                        setFilters(null);
                        setKey(uuidv4());
                    }}>azzera filtri</Button>
                </Stack>
            </React.Fragment>
        )
    }

    const tableCellStyle = {
        fontWeight: 700,
    };
    const tableRowStyleNoBorder = {
        fontWeight: 700,
        borderBottomWidth: 0,
    };
    const tableCellStyleNoBorder = {
        borderBottomWidth: 0,
    };

    function Row(props: any) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        const [pagato, setpagato] = React.useState(row.pagato);
        const dispatch = useDispatch();
        const root = useSelector((state: RootStateOrAny) => state);
        return (
            <React.Fragment>
                <TableRow onClick={() => setOpen(!open)} hover={true} selected={open}
                    sx={{
                        cursor: "pointer",
                        borderBottomStyle: "hidden",
                        '& > *': { borderBottom: 'unset' }
                    }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Chip label={Moment(row.data).format('DD/MM/YY HH:mm')} />
                    </TableCell>
                    <TableCell align="right">{formatNumOrdine(row.num_ordine)}</TableCell>
                    <TableCell align="right">{row.cliente_descrizione}</TableCell>
                    <TableCell align="right">{row.qty}</TableCell>
                    {/* <TableCell align="right">{row.tipo_pagamento}</TableCell>
                    <TableCell align="right">{row.pagato ? "si" : "no"}</TableCell> */}
                    <TableCell align="right">
                        {/*!pagato &&
                            <Button size={"small"} variant={"contained"} color={"secondary"} onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                dispatch(setLoadingMessage("pagamento in corso..."));
                                if (await Api.Ordine.pagato(row, dispatch)) {
                                    setpagato(true);
                                    props.onReload();
                                }
                                dispatch(setLoadingMessage(null))
                            }}>
                                pagato
                            </Button>
                        */}
                    </TableCell>
                    <TableCell align="right">{currencyFormat(row.tot)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ p: 0 }} colSpan={9}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{
                                p: 1,
                                /*                       backgroundColor: (theme) => theme.palette.action.hover*/
                            }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Dettaglio
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={tableRowStyleNoBorder} align="left">num_ordine</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="left">tipologia</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="left">sku</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="left">sottocategoria</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="left">venditore</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="center">quantità</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="center">in offerta</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="right">prezzo</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.history.map((historyRow) => (
                                            <TableRow onClick={() => {
                                                navigate(`/ordini/edit/${historyRow.guid}/${historyRow.prodotto_nome}`);
                                            }} hover={true} sx={{cursor: "pointer"}} key={uuidv4()}>
                                                <TableCell sx={tableCellStyleNoBorder} align="left" component="th"
                                                    scope="row">
                                                    {formatNumOrdine(historyRow.num_ordine)}
                                                </TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="left">{historyRow.tipologia}</TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="left">{historyRow.prodotto_sku}</TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                           align="left">{historyRow.prodotto_sottocategoria}</TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="left">{historyRow.fornitore_descrizione}</TableCell>                                                
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="center">{historyRow.quantita}</TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="center">{currencyFormat(historyRow.offerta)}</TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="right">{currencyFormat(historyRow.prezzo_unitario)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Box sx={{ pt: 2 }}></Box>
            <UiContainer>
                <Filters key={key} />
            </UiContainer>
            {items &&
                <UiContainer>
                    <Box
                        sx={{ width: { xs: "90vw", lg: "100%" }, overflowX: { xs: "scroll", lg: "hidden" } }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box sx={{ textAlign: "left", pt: 1, px: 2 }}>
                                {items.time &&
                                    <Typography variant={"caption"} sx={{ fontStyle: "italic" }}>
                                        Trovati{items.tot} risultati in {items.time} millisecondi
                                    </Typography>
                                }
                                {!items.time &&
                                    <Typography variant={"caption"} sx={{ fontStyle: "italic" }}>
                                        Trovati{items.tot} risultati
                                    </Typography>
                                }
                            </Box>
                            <Tooltip title="esporta i dati correnti">
                                <IconButton sx={{ m: 1 }} color="secondary" onClick={() => {
                                    var str = "";
                                    for (var key in filters) {
                                        if (str != "") {
                                            str += "&";
                                        }
                                        str += key + "=" + encodeURIComponent(filters[key]);
                                    }
                                    str += "&export=true";
                                    window.open("/db/ordine/all" + "?" + str);
                                }}>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell sx={tableCellStyle}>data ordine</TableCell>
                                    <TableCell sx={tableCellStyle} align="right">num ordine</TableCell>
                                    {/*                        <TableCell align="right">guid ordine</TableCell>*/}
                                    <TableCell sx={tableCellStyle} align="right">Effettuato Da</TableCell>
                                    <TableCell sx={tableCellStyle} align="right">quantità</TableCell>
                                    {  /* <TableCell sx={tableCellStyle} align="right">pagamento</TableCell>
                                    <TableCell sx={tableCellStyle} align="right">pagato</TableCell>*/ }
                                    <TableCell sx={tableCellStyle} align="right" />
                                    <TableCell sx={tableCellStyle} align="right">totale</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.data &&
                                    items.data.map((row) => (
                                        <Row onReload={async () => {
                                            await reload();
                                        }} key={uuidv4()} row={row} />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </UiContainer>
            }
        </React.Fragment>
    );
}

