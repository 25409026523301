import React, {useEffect} from 'react';
import '../custom.css'
import CssBaseline from '@mui/material/CssBaseline';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogProps,
    Menu,
    MenuItem,
    Snackbar,
    Stack,
    ThemeProvider,
    Toolbar,
    Typography
} from '@mui/material';
import Moment from 'moment';
import 'moment/locale/it';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {clearMessage, setDialogMessage, setGlobalTheme, setIsLogged, setLabels, setTipologia} from "../reducers";
import {theming} from "../Theming";
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from "@mui/icons-material/AccountCircle";
import Cart from "./cart/Cart";
import {DbDorsaleTipologia, DbIsLogged} from "../Db";
import MainMenu from './sidebar/Menu';
import {HookIsMobile} from "../utils/Helpers";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Login from "../pages/login/Login";
import {currencyFormat, showLogin} from "../utils/Functions";
import LinearProgress from "@mui/material/LinearProgress";
import Api from "../Api";
import {createTheme} from "@mui/material/styles";
import {BreadCrumb} from "./BreadCrumb";
import App_Styles from "./App_Styles";
import ChangePassword from '../pages/login/ChangePassword';
import AccettaPrivacyCliente from "./privacy/AccettaPrivacyCliente";
import {persistor} from "../PersistReducer";
import Credito from "./credito/Credito";

export default function App(props) {
    const drawerWidth = 280;
    const [loaded, setLoaded] = React.useState<any>(false);
    const [theme, setTheme] = React.useState<any>(null);
    const [cookie, setCookie] = React.useState<any>(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const root = useSelector((state: RootStateOrAny) => state);
    const navigate = useNavigate();
    const im = HookIsMobile();
    const [showPrivacy, setShowPrivacy] = React.useState<boolean>(false);
    Moment.locale("it");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    // const routeComponents = routes.map(({ path, component }, key) => <Route exact path={path} component={component}
    //     key={key} />);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [scroll] = React.useState<DialogProps['scroll']>('body');
    const [, setOpenDialog] = React.useState(false);
    const [dbtheme, setDbtheme] = React.useState<any>(null);
    useEffect(() => {
        (async () => {
            setDbtheme(await Api.Dorsale.theme(null, dispatch));
            setCookie(await Api.Dorsale.cookie(null, dispatch));
            setUserList(await Api.Dorsale.getUsers(null, dispatch));
            dispatch(setTipologia((await DbDorsaleTipologia(dispatch))));
            dispatch(setIsLogged((await DbIsLogged(dispatch))));
            dispatch(setLabels((await Api.Label.all(null, dispatch))));
            setLoaded(true);
        })();
    }, [])
    useEffect(() => {
        if (dbtheme) {
            window.document.title = dbtheme.nome;
            setTheme(theming(dbtheme));
            dispatch(setGlobalTheme(dbtheme));
        }
    }, [dbtheme])
    useEffect(() => {
        if (root.LoginData) {
            if (location.pathname.includes("/registrati") && (root.LoginData?.role != null && root.LoginData?.role != "GUEST")) {
                localStorage.removeItem("CartItems");
                document.location.href = `/db/logout?redirect=${location.pathname}`;
            }
            (async () => {
                if (root?.LoginData?.role?.toUpperCase() == "FORNITORE") {
                    if ((await (Api.Privacy.check(dispatch))) == false) {
                        setShowPrivacy(true);
                    }
                }
            })();
        }
    }, [root.LoginData])
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const darkTheme = createTheme({
        palette: {
            mode: root.GlobalTheme?.theme_mode?.toString().toLowerCase() ?? "dark",
        },
    });

	const [userList, setUserList] = React.useState<any>([]);
	const [isUserListModalOpen, setUserListModalOpen] = React.useState(false);

	const fetchUserList = async () => {
		try {
			const users = await Api.Dorsale.getUsers(null, dispatch);
			setUserList(users);
			setUserListModalOpen(true);
		} catch (error) {
			console.error("Errore nel recupero della lista utenti:", error);
		}
	};

	function clearAllCookies() {
		const cookies = document.cookie.split(";");

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf("=");
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
		}
	}

	const closeUserListModal = () => {
		setUserListModalOpen(false);
	};

	function GetShortName(name: string) {
		if (name == null) return "";
		var s = name?.split(" ");
		if (s.length > 1) return s[0].charAt(0) + s[1].charAt(0);
		return s[0].charAt(0) + s[0].charAt(1);
	}

	return (
		<React.Fragment>
			{root.GlobalTheme?.google_font != null && (
				<React.Fragment>
					<App_Styles />
				</React.Fragment>
			)}
			{!loaded && (
				<Box
					sx={{
						display: "table",
						position: "fixed",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<CircularProgress />
				</Box>
			)}
			{theme != null && (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						{root?.ShowLoading && (
							<Box
								sx={{
									display: "block",
									position: "fixed",
									top: "0",
									left: "0",
									width: "100%",
									zIndex: 9999,
								}}>
								<LinearProgress color={"secondary"} />
							</Box>
						)}
						{!showLogin(root, location) && loaded && (
							<Box
								sx={{
									display: "flex",
									backgroundColor: root?.GlobalTheme?.main_bgcolor,
								}}>
								{loaded && (
									<React.Fragment>
										<AppBar
											position='fixed'
											sx={{
												boxShadow: 0,
												width: {
													sm: `calc(100% - ${drawerWidth}px)`,
												},
												ml: {
													sm: `${drawerWidth}px`,
												},
											}}>
											<Toolbar> {/* <-- layout navbar "responsive" */}
												<IconButton
													color='inherit'
													aria-label='open drawer'
													edge='start'
													onClick={handleDrawerToggle}
													sx={{
														mr: 2,
														display: {
															sm: "none",
														},
													}}>
													<MenuIcon />
												</IconButton>
												<Typography
													variant='h6'
													style={{
														flexGrow: 1,
													}}>
													{""}
												</Typography>

												{/* right navbar buttons */}
												<Stack
													spacing={0} direction='row' justifyContent='center' alignItems='center'>
													{!im && root.Tipologia != "ENTERPRISE" && root.isLogged == true && <Credito />}
													{root?.isLogged == true && <Cart />}
													{root?.isLogged == true && (
														<Button
															sx={{
																fontWeight: 700,
															}}
															onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
																setAnchorEl(event.currentTarget);
															}}
															aria-label='account of current user'
															aria-controls='menu-appbar'
															aria-haspopup='true'
															variant='contained'
															disableElevation
															startIcon={<AccountCircle />}>
															{!im ? root.LoginData.name : GetShortName(root.LoginData.name)}
														</Button>
													)}
												</Stack>
												<Menu
													id='menu-appbar'
													anchorEl={anchorEl}
													anchorOrigin={{
														vertical: "top",
														horizontal: "right",
													}}
													keepMounted
													transformOrigin={{
														vertical: "top",
														horizontal: "right",
													}}
													open={Boolean(anchorEl)}
													onClose={() => {
														setAnchorEl(null);
													}}
													onClick={() => {
														setAnchorEl(null);
													}}>
													{root?.isLogged === true && root.LoginData?.email?.trim() != "" && (
														<MenuItem
															onClick={() => {
																navigate("/i-miei-dati");
															}}>
															I Miei Dati
														</MenuItem>
													)}
													{root?.isLogged === true && root?.Tipologia != "ENTERPRISE" && (
														<MenuItem
															onClick={() => {
																navigate("/ricarica");
															}}>
															Ricarica ({currencyFormat(root?.Credito)})
														</MenuItem>
													)}
													{root?.isLogged === true && root?.Tipologia != "ENTERPRISE" && userList.length > 0 && (
														<MenuItem onClick={fetchUserList}>Cambia Attività</MenuItem>
													)}
													{root?.isLogged === true && (
														<MenuItem
															onClick={() => {
																localStorage.removeItem("CartItems");
																document.location.href = "/db/logout";
															}}>
															Esci
														</MenuItem>
													)}
												</Menu>
											</Toolbar>
											<BreadCrumb />
										</AppBar>
										<ThemeProvider theme={darkTheme}>
											<Box
												component='nav'
												sx={{
													width: {
														sm: drawerWidth,
													},
													flexShrink: { sm: 0 },
												}}>
												<Drawer
													className={"myDrawer"}
													container={window.document.body}
													variant='temporary'
													open={mobileOpen}
													onClose={handleDrawerToggle}
													ModalProps={{
														keepMounted: true,
													}}
													sx={{
														display: {
															xs: "block",
															sm: "none",
														},
														"& .MuiDrawer-paper": {
															boxSizing: "border-box",
															width: drawerWidth,
														},
													}}>
													<MainMenu onclose={handleDrawerToggle} />
												</Drawer>
												<Drawer
													className={"myDrawer"}
													variant='permanent'
													sx={{
														display: {
															xs: "none",
															sm: "block",
														},
														"& .MuiDrawer-paper": {
															boxSizing: "border-box",
															width: drawerWidth,
														},
													}}
													open>
													<MainMenu />
												</Drawer>
											</Box>
										</ThemeProvider>
										<Box
											component='main'
											sx={{
												flexGrow: 1,
												p: im ? 0 : 1,
											}}>
											<Toolbar />

											<Box className={"ChildWrapper"}>
												<Box
													className={"ChildContainer"}
													sx={{
														mt: 0,
														pt: im ? 7 : 7,
														overflowX: 'auto'
													}}>

													<Outlet />
												</Box>
											</Box>
										</Box>

									</React.Fragment>
								)}
							</Box>
						)}
						{showLogin(root, location) && loaded === true && (
							<React.Fragment>
								<Box
									sx={{
										width: "100vw",
										height: "100vh",
										backgroundImage: "url('/db/dorsale/loginimage')",
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}
								/>
								<Login />
							</React.Fragment>
						)}
						<Snackbar
							open={root?.MESSAGE != ""}
							autoHideDuration={6000}
							onClose={() => {
								dispatch(clearMessage(null));
							}}>
							<Alert
								onClose={() => {
									dispatch(clearMessage(null));
								}}
								severity={root?.SEVERITY}
								variant='filled'>
								{root?.MESSAGE}
							</Alert>
						</Snackbar>
						{root.LoadingMessage != null && (
							<Dialog
								fullScreen={im}
								open={true}
								onClose={() => {
									setOpenDialog(true);
								}}
								scroll={scroll}>
								<DialogContent dividers={scroll === "paper"}>
									<Box>
										<Box sx={{ mb: 1 }}>{root.LoadingMessage}</Box>
										<LinearProgress color={"secondary"} />
									</Box>
								</DialogContent>
							</Dialog>
						)}
						<AccettaPrivacyCliente
							ruolo={"fornitore"}
							show={showPrivacy}
							onClose={async (privacy_content) => {
								await Api.Anagrafica.saveconsensi(privacy_content, dispatch);
							}}
						/>
						{!showLogin(root, location) && <ChangePassword />}
						{root.DialogMessage != null && (
							<Dialog
								fullScreen={im}
								open={true}
								onClose={() => {
									setOpenDialog(true);
								}}
								scroll={scroll}>
								<DialogContent dividers={scroll === "paper"}>
									<Box>
										<Box sx={{ mb: 1 }}>
											<div
												dangerouslySetInnerHTML={{
													__html: root.DialogMessage.replaceAll("\n", "<BR>"),
												}}
											/>
										</Box>
										<Box>
											<Button
												variant={"contained"}
												onClick={() => {
													dispatch(setDialogMessage(null));
												}}>
												Ho capito
											</Button>
										</Box>
									</Box>
								</DialogContent>
							</Dialog>
						)}
						<AccettaPrivacyCliente
							ruolo={"fornitore"}
							show={showPrivacy}
							onClose={async (privacy_content) => {
								await Api.Anagrafica.saveconsensi(privacy_content, dispatch);
							}}
						/>
					</ThemeProvider>
				</LocalizationProvider>
			)}
			<Dialog
				open={isUserListModalOpen}
				onClose={() => setUserListModalOpen(false)}
				scroll='paper'
				maxWidth='sm'>
				<DialogContent>
					<Typography variant='h6'>Lista Utenti</Typography>
					{userList.map((user, index) => (
						<div key={index}>
							<Button
								variant='text'
								onClick={() => {
									console.log("Testo cliccato:", user.nome_attivita);

									try {
										clearAllCookies();
										const response = Api.public.switchLogin(user.nome_attivita, dispatch);
										console.log("Risposta dall'endpoint POST:", response);
										persistor.purge(); // Reset dello stato persistente
										closeUserListModal();
										navigate("/dashboard");
										window.location.reload();
									} catch (error) {
										console.error("Errore nella chiamata POST:", error);
									}
								}}
								style={{
									textAlign: "left",
									justifyContent: "flex-start",
									padding: 0,
								}}>
								<Typography component='div'>
									<strong>{user.nome_attivita}</strong>
								</Typography>
							</Button>
							<hr />
						</div>
					))}
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
