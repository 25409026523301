import React, { useEffect } from 'react';
import {
    Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle, Menu,
    MenuItem,
    Stack, TextField
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
    AddCartItem,
    ClearCartItems, RemoveCartItem,
    setCartItems, setDialogMessage,
    setLoadingMessage,
    showInfo
} from "../../reducers";
import Chip from "@mui/material/Chip";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { currencyFormat, getNewProdottoImmagine, reloadCredito, reloadNumbers } from "../../utils/Functions";
import Api from "../../Api";
import { HookIsMobile } from "../../utils/Helpers";
import TextEllipsis from 'react-text-ellipsis';
import { useNavigate } from "react-router-dom";
import { TextNormaBold, TextNormal } from "../../utils/Text";
import IconButton from "@mui/material/IconButton";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Menu_DEFAULT from "../sidebar/Menu_DEFAULT";
import { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

export default function Cart() {

    const [cartAnchor, setCartAnchor] = React.useState<any>(null);
    const [tot, setTot] = React.useState<any>(0);
    const [prodotti, setProdotti] = React.useState<any>(null);
    const cartOpen = Boolean(cartAnchor);
    const root = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();
    const im = HookIsMobile();
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const navigate = useNavigate();

    let cartProd = Array<any>();
    console.log(root.CartItems)

    function PersistCart() {
        if (root.CartItems.length > 0)
            localStorage.setItem("CartItems", JSON.stringify(root.CartItems));
        else {
            const data = localStorage.getItem("CartItems");
            if (data != null)
                dispatch(setCartItems(JSON.parse(data as string)));
        }
    }

    useEffect(() => {
        const cats = root.CartItems.reduce((catsSoFar, { sku }) => {
            if (!catsSoFar[sku]) catsSoFar[sku] = [];
            catsSoFar[sku].push(sku);
            return catsSoFar;
        }, {});

        var prodotti = Object.keys(cats).map(function (key) {
            return cats[key]
        });

        setProdotti(prodotti);

        setTot(root.CartItems.map((n) => n.offerta != null ? n.offerta : n.prezzo).reduce((a, b) => {
            return a + b;
        }, 0));

        cartProd = new Array<any>();

        /*        PersistCart();*/

        if (root.CartItems.length == 0)
            setCartAnchor(null);

    }, [root.CartItems])


    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: 0,
            top: 0,
        },
    }));


    return (
        <React.Fragment>
            <Button
                sx={{ fontWeight: 700 }}
                onClick={(event) => {
                    if (root.CartItems && root.CartItems.length > 0)
                        setCartAnchor(event.currentTarget)
                }}
                aria-controls="basic-menu"
                aria-haspopup="true"
                variant="contained"
                disableElevation
                startIcon={<ShoppingCartIcon />}>
                ({root.CartItems?.length})
            </Button>
            {/* {root.Dorsale && root.Dorsale.layout == "WOLF" && !im &&
                <IconButton onClick={(event) => {
                    if (root.CartItems && root.CartItems.length > 0)
                        setCartAnchor(event.currentTarget)
                }} size="large">
                    <StyledBadge badgeContent={root.CartItems?.length ?? 0} color="secondary">
                        <ShoppingCartOutlinedIcon />
                    </StyledBadge>
                </IconButton>
            } */}
            <Menu
                id="basic-menu"
                anchorEl={cartAnchor}
                open={cartOpen}
                onClose={() => {
                    setCartAnchor(null);
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {prodotti && prodotti.length > 0 && prodotti.sort().map((row, index) => {
                        const prod = root.CartItems.filter(x => x.sku == row[0])[0];
                        if (prod == null)
                            return (null);
                        cartProd.push({ prodotto: prod, qty: row.length });
                        return (
                            <React.Fragment key={index}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Remy Sharp" src={prod.ProdottoToMedia ? getNewProdottoImmagine(prod.ProdottoToMedia[0]) : ''} />
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Stack spacing={2}
                                            direction="column"
                                            justifyContent="start"
                                            alignItems="start">
                                            <Box sx={{ fontSize: 17, fontWeight: 700 }}>{prod.nome}</Box>
                                            <Box sx={{ fontSize: 15 }}>
                                                <TextEllipsis
                                                    lines={3}>
                                                    {prod.descrizione}
                                                </TextEllipsis>
                                            </Box>
                                            <Stack spacing={2}
                                                sx={{ mt: 1, width: "100%" }}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center">
                                                <Chip sx={{ fontWeight: 700 }}
                                                    label={!prod.offerta ? currencyFormat(prod.prezzo) : currencyFormat(prod.offerta)} />
                                                {/*                                   <Button size={"small"} variant={"outlined"} onClick={() => {
                                                    dispatch(RemoveCartItem(prod));
                                                }}>rimuovi</Button>*/}
                                                <TextField
                                                    select
                                                    size={"small"}
                                                    name="length"
                                                    type={"number"}
                                                    value={row.length}
                                                    sx={{ textAlign: 'left' }}
                                                    aria-label='test1'
                                                    onChange={(e) => {
                                                        if (e.target.value == "Rimuovi") {
                                                            dispatch(RemoveCartItem(prod));
                                                        } else {
                                                            dispatch(RemoveCartItem(prod));
                                                            const originalCount = root.CartItems.filter(x => x.sku == row[0]).length;
                                                            const selectedValue = e.target.value as unknown as number;
                                                            for (let i = 0; i < selectedValue; i++) {
                                                                dispatch(AddCartItem(prod));
                                                            }
                                                        }
                                                    }}
                                                    variant="outlined">
                                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, "Rimuovi"].map((option) => (
                                                        <MenuItem
                                                            className='cartProdCount'
                                                            key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Stack>
                                        </Stack>
                                    </ListItemText>
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        );
                    })}
                </List>
                {
                    root.CartItems.length > 0 &&
                    <Box>
                        <Stack spacing={1}
                            sx={{ p: 3 }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            {/*   <Chip color="primary" sx={{fontWeight: 700}}
                                  label={currencyFormat(tot)}/>*/}
                            <TextNormaBold>
                                {currencyFormat(tot)}
                            </TextNormaBold>
                            <Button color="primary" variant={"contained"} endIcon={<CreditCardIcon />}
                                onClick={async () => {
                                    navigate("/pagamento");
                                    setCartAnchor(null);
                                }}
                            >CONCLUDI</Button>
                        </Stack>
                    </Box>
                }
            </Menu>
        </React.Fragment>
    )
}
