import React, { useEffect } from 'react';
import { DbAnagrafica } from "../../Db";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import { HookIsMobile } from "../../utils/Helpers";
import { Box, Button, Divider } from "@mui/material";
import { currencyFormat, getNewProdottoImmagine, getProdottoImmagine } from "../../utils/Functions";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Api from "../../Api";
import Avatar from "@mui/material/Avatar";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import IconButton from "@mui/material/IconButton";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import TextEllipsis from 'react-text-ellipsis';
import { TextDescription, TextErrorSmall, TextTitle, TextTitleSecondarySmall, TextTitleSmall } from "../../utils/Text";
import { HomeBox } from "./Home";
import { ModalRichiediPrezzo } from "../../components/modals/ModalRichiediPrezzo";
import CartProdottiAcquista from "../catalogo/CartProdottiAcquista";

export function HomeProdottiItem(props: any) {
    let { categoria } = useParams<any>();
    let { tipologia } = useParams<any>();
    let { guidsottocategoria } = useParams<any>();
    let { guidcategoria } = useParams<any>();
    let { nomecategoria } = useParams<any>();
    let { seo } = useParams<any>();
    let { sku } = useParams<any>();
    const [fornitori, setFornitori] = React.useState<any>([]);
    const [richiediPrezzo, setRichiediPrezzo] = React.useState<any>(null);
    const im = HookIsMobile();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const imageSizeW = im ? "100%" : 130;
    const imageSizeH = 130;
    const p = props.prodotto;
    const prodotti = props.prodotti;
    const pIndex = props.pIndex;
    useEffect(() => {
        (async () => {
            setFornitori((await DbAnagrafica({ ruolo: "FORNITORE", flat: true }, dispatch))?.data);
            if (sku != null) {
                var ssss = await Api.Prodotto.all({ sku: sku }, dispatch);
                props.onZoom(ssss);
            }
        })();
    }, [])
    return (
        <React.Fragment>
            <Stack style={props.style} sx={{ backgroundColor: "", height: im ? "auto" : 140 }}
                direction={im ? "column" : "row"}
                alignItems={"stretch"}
                justifyContent={"stretch"}
                alignContent={"stretch"} spacing={1}>
                <React.Fragment>
                    <Avatar variant="rounded"
                        sx={{ width: imageSizeW, height: imageSizeH }}
                        src={getNewProdottoImmagine(p?.ProdottoToMedia[0], im ? Math.round(window.innerWidth) : 300)} />
                </React.Fragment>
                <Stack direction={"column"} alignItems={im ? "center" : "stretch"}
                    justifyContent={"space-between"}
                    alignContent={"space-between"} spacing={1}
                    sx={{ width: "100%" }}>
                    <TextTitle>
                        <TextEllipsis
                            lines={1}>
                            {p.nome}
                        </TextEllipsis>
                    </TextTitle>
                    <TextTitleSmall>
                        <TextEllipsis
                            lines={1}>
                            {fornitori?.filter(x => x.email == p.email_fornitore)[0]?.ragione_sociale ?? "n/a"}
                        </TextEllipsis>
                    </TextTitleSmall>
                    <TextDescription>
                        <TextEllipsis
                            lines={2}>
                            {p.descrizione}
                        </TextEllipsis>
                    </TextDescription>
                    <Stack sx={{ width: "100%" }} direction={im ? "column" : "row"}
                        alignItems={"center"}
                        alignContent={"center"}
                        justifyContent={"space-between"}
                        spacing={1}>
                        <Stack direction={"row"}
                            alignItems={"center"} spacing={2}>
                            {(!p.offerta && p.prezzo > 0) &&
                                <TextTitleSecondarySmall style={{ whiteSpace: "noWrap" }}>
                                    {currencyFormat(p.prezzo)}
                                </TextTitleSecondarySmall>
                            }
                            {!!(p.offerta && p.prezzo > 0) &&
                                <React.Fragment>
                                    <TextTitleSmall
                                        sx={{ textDecoration: "line-through", whiteSpace: "noWrap" }}>
                                        {currencyFormat(p.prezzo)}
                                    </TextTitleSmall>
                                    <TextTitleSecondarySmall style={{ whiteSpace: "noWrap" }}>
                                        {currencyFormat(p.offerta)}
                                    </TextTitleSecondarySmall>
                                </React.Fragment>
                            }
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} spacing={0}>
                            {p.quantita == 0 &&
                                <Box>
                                    <TextErrorSmall>
                                        ESAURITO
                                    </TextErrorSmall>
                                </Box>
                            }
                            <IconButton color={"default"} onClick={async () => {
                                props.onZoom(p);
                                const seoString = seo?.toString()
                                const nextURL = `/categoria/${tipologia}/${guidcategoria}/${nomecategoria}/${guidsottocategoria}/${encodeURIComponent(seoString || '')}/${p.sku}`;
                                const nextTitle = 'My new page title';
                                const nextState = { additionalInformation: 'Updated the URL with JS' };
                                navigate("", { state: nextState + nextTitle + nextURL })
                                // window.history.pushState(nextState, nextTitle, nextURL);
                            }}>
                                <ZoomOutMapIcon />
                            </IconButton>
                            {p.quantita > 0 && p.prezzo > 0 &&
                                <IconButton color={"secondary"} onClick={async () => {
                                    props.onAddToCart(p);
                                }}>
                                    <AddShoppingCartIcon />
                                </IconButton>
                            }
                            {p.prezzo <= 0 &&
                                <Button
                                    sx={{ whiteSpace: "nowrap" }}
                                    size={"small"}
                                    variant={"outlined"}
                                    onClick={() => {
                                        setRichiediPrezzo(p);
                                    }}
                                >
                                    {p.richiedi_preventivo_t == null ? "richiedi preventivo" : p.richiedi_preventivo_t}
                                </Button>
                            }
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <ModalRichiediPrezzo setRichiediPrezzo={setRichiediPrezzo} richiediPrezzo={richiediPrezzo} />
            {prodotti.data.length - 1 != pIndex &&
                <Box sx={{ pb: 2, pt: 1 }}>
                    <Divider />
                </Box>
            }
        </React.Fragment>
    )
}

export default React.memo(function HomeProdotti(props: any) {
    const dispatch = useDispatch();
    const [prodotti, setProdotti] = React.useState<any>(null);
    const [bannerInHome, setbannerInHome] = React.useState<any>(null);
    const [prodotto, setProdotto] = React.useState<any>(null);
    const [schedaProdotto, setSchedaProdotto] = React.useState<any>(null);
    const [fornitori, setFornitori] = React.useState<any>([]);
    const im = HookIsMobile();
    const navigate = useNavigate();
    const location = useLocation();
    const root = useSelector((state: RootStateOrAny) => state);
    useEffect(() => {
        (async () => {
            setFornitori((await DbAnagrafica({ ruolo: "FORNITORE", flat: true }, dispatch))?.data);
        })();
    }, [])
    useEffect(() => {
        (async () => {
            let prodotti_take: number;
            const dbBannerInHome = await Api.Banner.all({
                settori: JSON.stringify(props.settori),
                banner_in_evidenza: true,
            }, dispatch);
            if (!dbBannerInHome.data) {
                if (props.layout == "DEFAULT")
                    prodotti_take = 4;
                else prodotti_take = 5;
                setbannerInHome(null);
            } else {
                if (props.layout == "DEFAULT")
                    prodotti_take = 4 - dbBannerInHome.tot;
                else prodotti_take = 5 - dbBannerInHome.tot;
                setbannerInHome(dbBannerInHome.data);
            }
            setProdotti(await Api.Prodotto.all({
                settori: JSON.stringify(props.settori),
                take: prodotti_take,
                cart: true,
                vetrina: true,
            }, dispatch));
        })();
    }, [props.settori, props.layout])

    function getBgImage(x: any) {
        if (x.guid_immagine)
            return `/db/media/get?guid=${x.guid_immagine}`;
        return x.image_url;
    }

    return (
        <React.Fragment>
            <HomeBox
                onClick={() => {
                    navigate("/in-evidenza");
                }}>
                <Stack direction={"column"} alignItems={"stretch"} justifyContent={"stretch"}
                    alignContent={"stretch"} spacing={0}>
                    {bannerInHome && bannerInHome && bannerInHome.map((x, i) => {
                        return (
                            <>
                                <Box sx={{
                                    cursor: "pointer",
                                    height: im ? "auto" : 140,
                                }}>
                                    {im &&
                                        <img
                                            src={getBgImage(x)}
                                            onClick={async () => {
                                                await Api.Banner.click({ guid: x.guid }, dispatch);
                                                if (x.link.startsWith("/"))
                                                    navigate(x.link);
                                                else
                                                    window.open(x.link);
                                            }}
                                            style={{
                                                width: "100%",
                                            }}>
                                        </img>
                                    }
                                    {!im &&
                                        <Box
                                            onClick={async () => {
                                                await Api.Banner.click({ guid: x.guid }, dispatch);
                                                if (x.link.startsWith("/"))
                                                    navigate(x.link);
                                                else
                                                    window.open(x.link);
                                            }}
                                            sx={{
                                                height: 140,
                                                width: "100%",
                                                backgroundImage: `url(${getBgImage(x)})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center",
                                                backgroundSize: "cover",
                                            }}>
                                        </Box>
                                    }
                                </Box>
                                <Box sx={{ pb: im ? 1 : 2, pt: im ? 0 : 1, height: 1 }}>
                                    {/*         <Divider/>*/}
                                </Box>
                            </>
                        )
                    })
                    }
                    <Box sx={{
                        pt: bannerInHome?.length > 0 ? 3 : 0,
                        pb: root?.Dorsale.layout == "WOLF" ? bannerInHome?.length > 0 ? 1 : 4 : bannerInHome?.length > 0 ? 4 : 7
                    }}>
                        <Stack sx={{ mb: bannerInHome?.length > 0 ? 0 : 0, pb: 1 }} direction={"row"}
                            alignItems={"center"}
                            alignContent={"center"}
                            justifyContent={"space-between"}>
                            <Box sx={{ fontSize: 15, fontWeight: 700, textTransform: "capitalize", opacity: .8 }}>
                                {"in evidenza"}
                            </Box>
                            <Button variant={"outlined"} size={"small"} color={"secondary"} sx={{}} onClick={() => {
                                navigate("/in-evidenza");
                            }}>
                                {"vedi tutti"}
                            </Button>
                        </Stack>
                    </Box>
                    {prodotti && prodotti.data && prodotti.data.map((p, pIndex) => {
                        return (
                            <HomeProdottiItem
                                pIndex={pIndex}
                                onZoom={(p) => {
                                    setSchedaProdotto(p);
                                }}
                                onAddToCart={(p) => {
                                    setProdotto(p);
                                }}
                                prodotti={prodotti}
                                prodotto={p} />
                        )
                    })}
                </Stack>
            </HomeBox>
            <CartProdottiAcquista prodotto={prodotto} schedaProdotto={schedaProdotto} onClose={() => {
                setProdotto(null);
                setSchedaProdotto(null);
            }} />
        </React.Fragment>
    );
})