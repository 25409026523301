import React from 'react';

import {createStore} from "redux";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

const state = {authenticated: false};

const reducer = (state, action) => ({...state, ...action});

const persistConfig = {
    key: "root",
    storage
};

export const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, state);

export const persistor = persistStore(store);